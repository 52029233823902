import { de } from "vuetify/lib/locale";

export default {
  einstellungen: "Einstellungen",
  dsfa_vorlage: "Möchten Sie eine Vorlage für die Erstellung der Datenschutzfolgenabschätzung verwenden?",
  benachrichtigung: "Benachrichtigung",
  benachrichtigungen: "Benachrichtigungen",
  benachrichtigungenEmail: "Benachrichtigungen per Email",
  benachrichtigungBetroffenen: "Benachrichtigung der Betroffenen",
  passwortzuruecksetzen: "Passwort zurücksetzen",
  persoenlicheEinstellungen: "Persönliche Einstellungen",
  WollenSieWeiterhinBenachrichtigungenErhalten: "Wollen Sie weiterhin Benachrichtigungen erhalten?",
  abmelden: "Abmelden",
  uebersicht: "Übersicht",
  vererbung_bestaetigen: "Vererbung bestätigen",
  risiken: "Risiken",
  sprache: "Sprache",
  keine_szenarien: "Keine Szenarien",
  keineDatenkategorienVorhanden: "Es wurden noch keine Datenkategorien ausgewählt. Wählen Sie unter Schritt 2 Datenkategorien aus, um anschließend Löschfristen zu erstellen.",
  szenario: "Szenario",
  organisation_verwalten: "Organisation verwalten",
  inherited_receiver_included: "Sie können keine Verarbeitungstätigkeit vererben, in welcher ein bereits vererbter Datenempfänger verwendet wird.",
  organisation_oeffnen: "Organisation öffnen",
  unternehmensstruktur: "Unternehmensstruktur",
  ressourcen: "Ressourcen",
  vorlagen: "Vorlagen",
  filter_loeschen: "Zurücksetzen",
  beispiel: "Das ist ein Beispieltext. Lorem Ipsum",
  aufgabenuebersicht: "Aufgabenübersicht",
  bearbeitung_vererbung_blockiert: "Das Bearbeiten vererbter Datensätze ist derzeit nicht möglich. Wir prüfen aktuell diese Funktionalität, um inkonsistente oder fehlerhafte Datenstrukturen zu vermeiden.",
  loeschen_vererbung_blockiert: "Das Löschen vererbter Datensätze ist derzeit nicht möglich. Wir prüfen aktuell diese Funktionalität, um inkonsistente oder fehlerhafte Datenstrukturen zu vermeiden.",
  datenempfaenger: "Datenempfänger",
  dokumentationen: "Dokumentationen",
  vvt: "Verzeichnis von Verarbeitungstätigkeiten",
  vvt_sha: "Verzeichnis von Verarbeitungs&shy;tätigkeiten",
  verzeichnisVerabeitungenImAuftrag: "Verzeichnis Verarbeitungen im Auftrag",
  verabeitungenImAuftrag: "Verarbeitungen im Auftrag",
  organisation_wechseln: "Organisation wechseln",
  generator: "Dokumentengenerator",
  dokument_erstellen: "Dokument erstellen",
  softwareloesungen: "Softwarelösungen",
  auftraggeber: "Auftraggeber",
  organisationseinstellungen: "Organisationseinstellungen",
  stammdaten: "Stammdaten",
  abbrechen: "Abbrechen",
  vererbung_erfolgreich: "Die Vererbung wird asynchron im Hintergrund ausgeführt. Dies kann je nach Umfang der Daten etwas Zeit in Anspruch nehmen.",
  schließen: "Schließen",
  speichern: "Speichern",
  speichernUndZurAnsicht: "Speichern & zur Ansicht",
  firma: "Firma",
  geschaeftsfuehrer: "Geschäftsführer",
  branche: "Branche",
  ausblenden: "ausblenden",
  ausfuellhinweise: "Ausfüllhinweise",
  strasse: "Straße",
  hausnummer: "Hausnummer",
  plz: "Postleitzahl",
  ort: "Ort",
  orte: "Orte",
  auswertungen: "Auswertungen",
  berichte: "Berichte",
  aufsichtsbehoerden: "Aufsichtsbehörden",
  aufsichtsbehoerde: "Aufsichtsbehörde",
  land: "Land",
  datenuebertragung_in_drittland: "Datenübertragung in ein Drittland",
  email: "E-Mail",
  telefon: "Telefon",
  webseite: "Webseite",
  datenpannen: "Datenpannen",
  dskoordinator: "Datenschutzkoordinator",
  dsb: "Datenschutzbeauftragter",
  stammdaten_bearbeiten: "Stammdaten bearbeiten",
  unternehmen_erstellen: "Unternehmen erstellen",
  keine_daten: "Keine Daten vorhanden",
  keine_dateien: "Keine Dateien vorhanden",
  dateien: "Dateien",
  Dateien: "Dateien",
  hochladen: "Hochladen",
  autocomplete_suche: "Eingabe zum Suchen ...",
  erstellen: "erstellen",
  abteilung: "Abteilung",
  abteilungen: "Abteilungen",
  upload_text_multiple: "Dateien hierher verschieben oder hier klicken um Dateien auszuwählen.",
  upload_text: "Datei hierher verschieben oder hier klicken um Datei auszuwählen.",
  upload_word: "Word-Datei hierher verschieben oder hier klicken um Word-Datei auszuwählen.",
  ausgewaehlte_dateien: "Ausgewählte Dateien",
  ausgewaehlte_datei: "Ausgewählte Datei",
  name: "Name",
  details: "Details",
  verantwortliche: "Verantwortliche",
  tage: "Tage",
  tag: "Tag",
  uhr: "Uhr",
  farbe: "Farbe",
  bis: "bis",
  typen: "Typen",
  bisZum: "bis zum",
  zeitraum: "Zeitraum",
  zeitpunkt: "Zeitpunkt",
  wochen: "Wochen",
  woche: "Woche",
  ende_am: "Ende am",
  monate: "Monate",
  monat: "Monat",
  risikoszenarien_bearbeiten: "Risikoszenarien bearbeiten",
  massnahmen_nicht_umgesetzt: "Folgende ausgewählte Maßnahmen sind bisher noch nicht umgesetzt:",
  risikoquellen_waehlen: "Wähle eine oder mehrere Risikoquellen aus und begründe die Ursachen, sowie die potenziellen Schäden jeweils.",
  verarbeitungsvorgaenge_beschreiben: "Beschreibe hier die Verarbeitungsvorgänge im Detail unter Berücksichtigung der Zwecke der Verarbeitungstätigkeit.",
  wiederholung_alle: "Wiederholung alle",
  jahre: "Jahre",
  jahr: "Jahr",
  created_info: "Details",
  entfernen: "Entfernen",
  loeschen: "Löschen",
  loeschenAusDerGruppe: "Löschen aus der Gruppe",
  wiederholungsende: "Wiederholungsende",
  profilsprache: "Profilsprache",
  diesen_kommentar: "diesen Kommentar",
  task_1: "Konfigurieren Sie hier eine wiederkehrende Aufgabe. Weitere Informationen erhalten Sie in unserem FAQ.",
  ansprechpartner: "Ansprechpartner",
  tom: "Technische und organisatorische Maßnahmen",
  tom_sha: "Technische und organi&shy;satorische Maß&shy;nahmen",
  loeschkonzept: "Löschkonzept",
  prioritaet: "Priorität",
  priority_0: "Gering",
  priority_1: "Mittel",
  priority_2: "Hoch",
  loeschen_1: "Möchten Sie",
  rolle_aktualisieren: "Rolle aktualisieren",
  ersatzbenutzer: "Ersatzbenutzer",
  rolle_aendern: "Rolle ändern",
  hinweis_benutzer_loeschen: "Der Benutzer bleibt 30 Tage weiter bestehen, kann jedoch nicht mehr ausgewählt werden. Nach Ablauf der 30 Tage wird der Benutzer endgültig entfernt.",
  hinweis_benutzer_loeschen_plural: "Die zur Löschung ausgewählten Benutzer bleiben 30 Tage weiter bestehen, können jedoch nicht mehr ausgewählt werden und auf keine der Gesellschaften innerhalb der Organisationsstruktur zugreifen. Nach Ablauf der 30 Tage werden die Benutzer endgültig entfernt.",
  achtung_loeschen: "ACHTUNG - Sie entfernen den Nutzer aus allen Organisationen.",
  diese_stunden: "diese Stunden",
  loeschen_2: "wirklich entfernen",
  dsfa: "Datenschutzfolgenabschätzung",
  dsfa_sha: "Datenschutz&shy;folgen&shy;abschätzung",
  externe_kommunikation: "Externe Kommunikation",
  externe_kommunikation_sha: "Externe Kom&shy;muni&shy;kation",
  auditsUndChecklisten: "Audits & Checklisten",
  audits: "Audits",
  audit: "Audit",
  audit_company: "Audit Unternehmen",
  audit_datareceiver: "Audit Datenempfänger",
  checklisten: "Checklisten",
  maßnahme: "Maßnahme",
  art: "Art",
  startdatum: "Startdatum",
  enddatum: "Enddatum",
  datum: "Datum",
  eingangsdatum: "Eingangsdatum",
  datumBeginn: "Datum Beginn",
  uhrzeitBeginn: "Uhrzeit Beginn",
  datumEnde: "Datum Ende",
  uhrzeitEnde: "Uhrzeit Ende",
  uhrzeit: "Uhrzeit",
  heute: "Heute",
  stunden: "Stunden",
  zeit_erfassen: "Zeit erfassen",
  zeiterfassung: "Zeiterfassung",
  erstelldatum: "Erstelldatum",
  bezugderMaßnahme: "Bezug der Maßnahme",
  frist: "Frist",
  bezeichnung: "Bezeichnung",
  kommentare: "Kommentare",
  letzteBearbeitung: "Letzte Bearbeitung",
  vergangene_pruefungen: "Vergangene Prüfungen",
  wiederkehrende_pruefungen: "Wiederkehrende Prüfungen",
  pruefungen_konfigurieren: "Prüfungen konfigurieren",
  keine_wiederkehrung: "Für diesen Datenempfänger sind bisher keine wiederkehrenden Prüfungen konfiguriert.",
  bearbeitung: "Bearbeitung",
  blockierte_ressource: "Dieses Objekt wird aktuell bereits von {vorname} {nachname} bearbeitet.",
  status: "Status",
  StatusDerPruefungen: "Status der Prüfungen",
  keine_Tags_vorhanden: "Keine Tags vorhanden",
  funktion_nicht_vorhanden: "Diese Funktion ist in der Demo nicht verfügbar.",
  vorabeinschätzungDurchfuehrenText: "Möchten Sie eine Vorabeinschätzung durchführen, um eine Empfehlung zu erhalten, ob eine Meldung an die Aufsichtsbehörde oder die Benachrichtigung der Betroffenen notwendig ist?",
  neueDatenpanne: "Neue Datenpanne",
  name_der_Datenpanne: "Name der Datenpanne",
  vorabeinschaetzung: "Vorabeinschätzung",
  assessmentRechtlicherHinweis: "Die Komplexität einer Datenpanne wird durch die Eingaben auf ein Minimum reduziert. Die Empfehlung basiert auf vereinfachten Annahmen und kann deshalb nicht immer zutreffend sein. Die Empfehlung kann eine von mehreren Indikatoren für eine Entscheidung darstellen. Eine Datenpanne sollte im Einzelfall immer detailliert betrachtet und bewertet werden.",
  meldungAufsichtsbehoerde: "Meldung an die Aufsichtsbehörde",
  meldendePerson: "Meldende Person",
  keine_meldungen_vorhanden: "Keine Meldungen vorhanden",
  meldung_vom: "Meldung vom",
  anzahl_zu_klein: "Anzahl zu gering",
  meldepflichtig: "Meldepflichtig",
  faelligkeit: "Fälligkeit",
  eingangsuhrzeit: "Eingangsuhrzeit",
  datumDerMeldung: "Datum der Meldung",
  uhrzeitDermeldung: "Uhrzeit der Meldung",
  keine_benachrichtigungen_vorhanden: "Keine Benachrichtigungen vorhanden",
  meldefrist: "Meldefrist",
  benachrichtigung_vom: "Benachrichtigung vom",
  ansprechperson: "Ansprechperson",
  informationenZumVorfall: "Informationen zum Vorfall",
  beteiligungDritten: "Beteiligung von Dritten",
  risikoeinschaetzung: "Risikoeinschätzung",
  dritteBeteiligte: "Dritte Beteiligte",
  keineDrittenBeteiligten: "Keine dritten Beteiligten",
  erstelleDritteBeteiligte: "Erstellen Sie einen dritten Beteiligten",
  moeglicheSchaeden: "Mögliche Schäden",
  keineMoeglichenSchaeden: "Keine möglichen Schäden",
  erstelleMoeglicheSchaeden: "Erstellen Sie einen möglichen Schaden",
  zuordnungRisikoabstufungen: "Zuordnung zu Risikoabstufungen",
  meldungen: "Meldungen",
  meldung: "Meldung",
  keineMeldungengemeldet: "Es wurde noch keine Datenpannen an eine Aufsichtsbehörde gemeldet",
  erstelleMeldung: "Erstellen Sie eine Meldung",
  grenzueberschreitendeUndAndereBenachrichtigungen: "Grenzüberschreitende und andere Benachrichtigungen",
  informationAndererBehoerden: "Information anderer Behörden",
  weitereMitteilungenAnDatenschutzaufsichtsbehoerde: "Weitere Mitteilungen an Datenschutzaufsichtsbehörde",
  anrede: "Anrede",
  funktionImBetroffenenUnternehmen: "Funktion im betroffenen Unternehmen",
  artDesVorfalls: "Art des Vorfalls",
  beschreibung_des_Vorfalls: "Beschreibung des Vorfalls",
  ursacheDesVorfalls: "Ursache des Vorfalls",
  vorfallAnEinemBestimmtenZeitpunkt: "Hat sich der Vorfall an einem bestimmten Zeitpunkt oder in einem Zeitraum ereignet?",
  meldendePersonAuchAnsprechpartner: "Ist die meldende Person auch die Ansprechperson?",
  dauertDerVorfallNochAn: "Dauert der Vorfall noch an?",
  andauernderVorfall: "Andauernder Vorfall",
  feststellungDesVorfalls: "Feststellung des Vorfalls",
  ortDesVorfalls: "Ort des Vorfalls",
  beschreibung_der_Kenntnisse_des_Vorfalls: "Beschreibung der Kenntnisnahme des Vorfalls",
  datumDerFeststellungDesVorfalls: "Datum der Festellung des Vorfalls",
  uhrzeitDerFeststellungDesVorfalls: "Uhrzeit der Festellung des Vorfalls",
  betroffeneKategorienPersonenbezogenerDaten: "Betroffene Kategorien personenbezogener Daten",
  betroffeneBesondereKategorienPersonenbezogenerDaten: "Betroffene Besondere Kategorien personenbezogener Daten",
  kategorienBetroffenerPersonen: "Kategorien betroffener Personen",
  anzalDerBetroffenenPersonen: "Anzahl der betroffenen Personen",
  anzalDerBetroffenenDatensaetze: "Anzahl der betroffenen Datensätze",
  ergaenzendeMitteilungenAusichtsbehoerde: "Ergänzende Mitteilungen an die Aufsichtsbehörde",
  sindDritteBeteiligt: "Sind Dritte beteiligt?",
  wurdeDatenpanneGemeldet: "Wurde oder wird die Datenpanne an eine Aufsichtsbehörde gemeldet?",
  werdenBetroffeneUeberDatenpanneBenachrichtigt: "Wurden oder werden die Betroffenen über die Datenpanne benachrichtigt?",
  begründungFuerEntscheidung: "Begründung für die Entscheidung",
  wurdenMaßnahmenZurAbmilderungGetroffen: "Wurden bereits oder werden noch Maßnahmen zur Abmilderung des Risikos getroffen?",
  getroffeneMaßnahmen: "Getroffene Maßnahmen",
  beschreibungGetroffeneMaßnahmen: "Beschreibung der getroffenen Maßnahmen",
  geplanteMaßnahmen: "Geplante Maßnahmen",
  beschreibungGeplanteMaßnahmen: "Beschreibung der geplanten Maßnahmen",
  restrisiko: "Restrisiko",
  begruendungRestrisiko: "Begründung des Restrisikos",
  funktion: "Funktion",
  schwereDesMoeglichenSchadens: "Schwere des möglichen Schadens",
  neu: "Neu",
  wahr: "Wahr",
  falsch: "Falsch",
  artDerMeldung: "Art der Meldung",
  datumDerVorlaufigenMeldung: "Datum der vorläufigen Meldung",
  ReferenznummerAktenzeichenVorläufigenMeldung: "Referenznummer/Aktenzeichen der vorläufigen Meldung",
  ReferenznummerAktenzeichen: "Referenznummer/Aktenzeichen",
  MeldungErfolgtSpaeter: "Erfolgt die Meldung später als 72 Stunden nach Bekanntwerden?",
  begruendungVerspaeteteMeldung: "Begründung für die verspätete Meldung",
  datumDerBenachrichtigung: "Datum der Benachrichtigung",
  uhrzeitDerBenachrichtigung: "Uhrzeit der Benachrichtigung",
  artDerBenachrichtigung: "Art der Benachrichtigung",
  anzahlDerBenachrichtigtenBetroffenen: "Anzahl der benachrichtigten Betroffenen",
  inhaltDerBenachrichtigung: "Inhalt der Benachrichtigung",
  erstellungDerBenachrichtigung: "Erstellung der Benachrichtigung",
  moeglicheSchaedenSlider0: "geringfügig",
  moeglicheSchaedenSlider1: "überschaubar",
  moeglicheSchaedenSlider2: "substanziell",
  moeglicheSchaedenSlider3: "groß",
  meldendePersonAuchAnsprechpartnerResult: "Die meldende Person ist auch Ansprechpartner",
  erstmeldung: "Erstmeldung",
  meldungNichtZuSpaet: "Meldung erfolgt innerhalb von 72 Stunden nach Bekanntwerden",
  meldungZuSpaet: "Meldung erfolgt später als 72 Stunden nach Bekanntwerden",
  ergaenzendeMeldung: "Ergänzende Meldung",
  exporttypWaehlen: "Wähle eine Exportart aus",
  risiko: "Risiko",
  dokumente: "Dokumente",
  hochgeladeneDokumente: "Hochgeladene Dokumente",
  welcomeToKeyed: "Willkommen bei Keyed",
  welcome_text: "Lernen Sie den Aufbau des DSMS von Keyed® kennen und erhalten Sie fortlaufend neue Einblicke in bereits veröffentlichte Funktionen. Sollte eine Funktion noch nicht verfügbar sein, wird diese in Kürze veröffentlicht. Für Fragen steht dir dein persönlicher Ansprechpartner von Keyed® gerne zur Verfügung.",
  alle_anzeigen: "Alle Anzeigen",
  alle_Aufgaben_anzeigen: "Alle Aufgaben anzeigen",
  konformitaet: "Konformität in %",
  neue_pruefung: "Neue Prüfung erstellen",
  frist_wiederkehrende_Pruefungen: "Frist wiederkehrende Prüfungen",
  erinnerungsfrist_fuer_Pruefungen: "Erinnerungsfrist für Prüfungen",
  empfaenger_der_Pruefungen: "Empfänger der Prüfungen",
  backlog: "Backlog",
  logoHochladen: "Logo hochladen",
  in_bearbeitung: "In Bearbeitung",
  erledigt: "Erledigt",
  verantwortlicher: "Verantwortlicher",
  name_der_Verarbeitungstätigkeit: "Name der Verarbeitungstätigkeit",
  name_der_checkliste: "Name der Checkliste",
  name_der_checklistvorlage: "Name der Checklistvorlage",
  name_des_audits: "Name des Audits",
  name_der_auditvorlage: "Name der Auditvorlage",
  zustaendige_abteilungen: "Zuständige Abteilungen",
  zustaendige_Person: "Zuständige Person",
  beschreibung_der_Verarbeitungstätigkeit: "Beschreibung der Verarbeitungstätigkeit",
  zwecke_der_Verarbeitungstätigkeit: "Zwecke der Verarbeitungstätigkeit",
  hinzufuegen: "hinzufügen",
  beschreibung: "Beschreibung",
  schadensausmass: "Schadensausmaß",
  rechtsgrundlage_der_Verarbeitungstätigkeit: "Rechtsgrundlage der Verarbeitungstätigkeit",
  datenkategorie: "Datenkategorie",
  datenkategorien: "Datenkategorien",
  kategorie: "Kategorie",
  kategorien: "Kategorie(n)",
  software: "Software",
  loeschfrist: "Löschfrist",
  loeschfristen: "Löschfristen",
  externe_Empfaenger: "Externe Empfänger",
  jahresumsatz: "Jahresumsatz in Euro",
  datenschutzniveau: "Datenschutzniveau",
  vererben: "Vererben",
  vererbung_erstellen: "Vererbung erstellen",
  letzte_Aenderungen: "Letzte Änderungen",
  vererbt_von: "Vererbt von {company}",
  vererbungen: "Vererbungen",
  vererbt: "Vererbt?",
  vererbt_bekommen: "Vererbt bekommen?",
  risiko_wahrscheinlichkeit: "Risiko Wahrscheinlichkeit",
  risiko_ausmaß: "Risiko Ausmaß",
  geringes_risiko: "Geringes Risiko",
  mittleres_risiko: "Mittleres Risiko",
  hohes_risiko: "Hohes Risiko",
  erstellt_von: "Erstellt von",
  vererbung_an: "{name} wird aktuell an folgende Unternehmen vererbt:",
  routine_erstellen: "Löschroutine erstellen",
  keine_vererbung: "{name} wurde bisher an kein anderes Unternehmen vererbt.",
  periode_validierung: "Bei einer wiederkehrenden Aufgabe muss mindestens einer der Angaben größer 0 sein.",
  beschreibung_routine: "An dieser Stelle können Sie eine Löschroutine erstellen. Beachte, dass für jede einzelne Löschfrist eine wiederkehrende Aufgabe erzeugt wird. Wählen Sie dazu die Personen aus, welche für die Löschroutine der Datenkategorie {category} in der Verarbeitungstätigkeit {activity} zuständig sind.",
  richtlinien: "Richtlinien",
  richtlinien_text: "Interne Richtlinien für den Umgang mit personenbezogenen Daten stellen sehr gute Orientierungen für Beschäftigte dar. Es werden Vorlagen für die Erarbeitung von Richtlinien zur Verfügung gestellt.",
  einwilligungen: "Einwilligungen",
  einwilligungen_text: "Einwilligungen müssen explizit für konkrete Zwecke erteilt werden. Hier werden verschiedene Vorlagen für unterschiedliche Zwecke bereitgestellt.",
  Vertraege_datenempfaenger: "Verträge für Datenempfänger",
  Vertraege_datenempfaenger_text: "Gerade bei der Datenübermittlung muss ein angemessenes Schutzniveau vertraglich zugesichert werden. Für die marktüblichen Konstellationen werden hier Vorlagen für diese Verträge zur Bearbeitung angeboten.",
  interne_Dokumente: "Interne Dokumente",
  persoenliches_Dashboard: "Persönliches Dashboard",
  zum_persoenlichen_dashboard: "Zum persönlichen Dashboard",
  organisationsuebergreifende_Daten: "Hier siehst du organisationsübergreifende Daten",
  verarbeitungen: "Verarbeitungen",
  vertraege: "Verträge",
  offene_Aufgaben: "Offene Aufgaben",
  tom_abkuerzung: "TOM",
  allgemeines: "Allgemeines",
  pruefungen: "Prüfungen",
  aendern: "Ändern",
  orte_der_Verarbeitung: "Orte der Verarbeitung",
  keine_orte: "Keine Orte der Verarbeitung",
  geeignete_garantien: "Geeignete Garantien und Ausnahmen",
  unterauftragnehmer: "Unterauftragnehmer",
  auftragnehmer: "Auftragnehmer",
  ersteller: "Ersteller",
  vertragsunterlagen: "Vertragsunterlagen",
  vertrag_erstellen: "Vertrag erstellen",
  verknuepfte_Dokumentation: "Verknüpfte Dokumentation",
  einstellungen_fuer_Pruefungen: "Einstellungen für Prüfungen",
  ort_der_Verarbeitung: "Ort der Verarbeitung",
  type_communication: "Typen Externe Kommunikation",
  orte_der_verarbeitung: "Orte der Verarbeitung",
  keine_speicherorte: "Keine Orte der Verarbeitung hinterlegt.",
  demo_aufgaben_nicht_verschoben: "In der Demo können die Aufgaben nicht verändert oder verschoben werden.",
  fuegen_Sie_eine_Tom_hinzu: "Fügen Sie eine TOM hinzu",
  umgesetzte_maßnahmen: "Umgesetzte Maßnahmen",
  geplante_maßnahmen: "Geplante Maßnahmen",
  zwecke_der_Verarbeitung: "Zwecke der Verarbeitung",
  vorlage: "Vorlage",
  bearbeiten_klein: "bearbeiten",
  label: "Label",
  aufgaben_label: "Aufgaben-Label",
  benachrichtigung_erfolgt: "Benachrichtigung erfolgt?",
  datenpanne_info_ansehen: "Datenpanne Meldungen ansehen",
  datenpanne_info_erstellen: "Datenpanne Meldungen erstellen",
  datenpanne_info_bearbeiten: "Datenpanne Meldungen bearbeiten",
  datenpanne_info_loeschen: "Datenpanne Meldungen löschen",
  datenpanne_report_ansehen: "Datenpanne Benachrichtigung ansehen",
  datenpanne_report_erstellen: "Datenpanne Benachrichtigung erstellen",
  datenpanne_report_bearbeiten: "Datenpanne Benachrichtigung bearbeiten",
  datenpanne_report_loeschen: "Datenpanne Benachrichtigung löschen",
  benachrichtigungspflicht: "Benachrichtigungspflicht",
  word_ansehen: "Word Vorlage ansehen",
  daten: "Daten",
  grund: "Grund",
  objekte: "Objekte",
  datei: "Datei",
  vertrag: "Vertrag",
  checkliste: "Checkliste",
  datenpanne: "Datenpanne",
  verarbeitung_sicheres_drittland: "Verarbeitung in sicherem Drittland?",
  verarbeitung_eu: "Verarbeitung in der EU?",
  verarbeitungstaetigkeit_auftrag: "Verarbeitung im Auftrag",
  datenschutzfolgenabschaetzung: "Datenschutzfolgenabschätzung",
  resource_ort: "Ressource Ort",
  resource_aufbewahrung: "Ressource Aufbewahrungsort",
  resource_zweck: "Ressource Zweck",
  resource_rechtsgrundlage: "Ressource Rechtsgrundlage",
  resource_personal_data: "Ressource Kategorie personenbezogener Daten",
  resource_special_personal_data: "Ressource Besondere Kategorie personenbezogener Daten",
  resource_betroffene: "Ressource Kategorie Betroffene",
  resource_massnahme: "Ressource Maßnahme",
  resource_loeschfrist: "Ressource Löschfrist",
  resource_status: "Ressource Status",
  resource_risikoquelle: "Ressource Risikoquelle",
  resource_dokumentenkategorie: "Ressource Dokumentenkategorie",
  resource_receiver_category: "Ressource Kategorie Empfänger",
  resource_label: "Ressourcen-Label",
  resource_aufsicht: "Aufsichtsbehörde",
  resource_datenpanne_typ: "Ressource Typ Datenpanne",
  resource_datenpanne_grund: "Ressource Grund Datenpanne",
  resource_externe_kommu_typ: "Ressource Typ Externe Kommunikation",
  aufgabe: "Aufgabe",
  aufgabe_zeiterfassung: "Aufgabe Zeiterfassung",
  vorlage_verarbeitung: "Vorlage Verarbeitungstätigkeit",
  vorlage_audit: "Vorlage Audit",
  vorlage_checklist: "Vorlage Checkliste",
  vorlage_dsfa: "Vorlage Datenschutzfolgenabschätzung",
  vorlage_dokument: "Vorlage Dokument",
  person: "Person",
  version: "Version",
  kommentar: "Kommentar",
  wiederherstellen: "Wiederherstellen",
  word_erstellen: "Word Vorlage erstellen",
  word_loeschen: "Word Vorlage löschen",
  pdf_ansehen: "PDF Vorlage ansehen",
  pdf_erstellen: "PDF Vorlage erstellen",
  pdf_bearbeiten: "PDF Vorlage bearbeiten",
  pdf_loeschen: "PDF Vorlage löschen",
  data_receiver_subcontractor_ansehen: "Datenempfänger Unterauftragnehmer ansehen",
  data_receiver_subcontractor_erstellen: "Datenempfänger Unterauftragnehmer erstellen",
  data_receiver_subcontractor_bearbeiten: "Datenempfänger Unterauftragnehmer bearbeiten",
  data_receiver_subcontractor_loeschen: "Datenempfänger Unterauftragnehmer löschen",
  data_receiver_storage_ansehen: "Datenempfänger Verarbeitungsorte ansehen",
  data_receiver_storage_erstellen: "Datenempfänger Verarbeitungsorte erstellen",
  data_receiver_storage_bearbeiten: "Datenempfänger Verarbeitungsorte bearbeiten",
  data_receiver_storage_loeschen: "Datenempfänger Verarbeitungsorte löschen",
  zwecke: "Zwecke",
  exportieren: "Exportieren",
  rechtsgrundlagen: "Rechtsgrundlagen",
  kategorien_personenbezogener_Daten: "Kategorie(n) personenbezogener Daten",
  personenbezogene_Daten: "Personenbezogene Daten",
  besondere_kategorien_personenbezogener_Daten: "Besondere Kategorie(n) personenbezogener Daten",
  besondere_kategorien_personenbezogener_Daten_vorhanden: "Sind Besondere Kategorie(n) personenbezogener Daten vorhanden?",
  besondere_personenbezogener_Daten: "Besondere personenbezogene Daten",
  kategorien_der_betroffenen: "Kategorie(n) der Betroffenen",
  kategorien_der_empfaenger: "Kategorie(n) der Empfänger",
  kategorien_dermaßnahmen: "Kategorie(n) der Maßnahmen",
  loeschfristen_und_loeschkonzept: "Löschfristen und Löschkonzept",
  datenuebertragung_in_drittlaender: "Datenübertragung in ein Drittland",
  risikobewertung: "Risikobewertung",
  betroffene: "Betroffene",
  empfaenger: "Empfänger",
  maßnahmen: "Maßnahmen",
  bewertung: "Bewertung",
  tochter_erstellen: "Tochterunternehmen erstellen",
  weiter: "Weiter",
  zurueck: "Zurück",
  zuruecksetzen: "Zurücksetzen",
  spezifische_massnahmen: "Spezifische Maßnahmen",
  neue_aufgabe: "Neue Aufgabe",
  dashboard: "Dashboard",
  machen_Sie_weiter: "Machen Sie weiter, wo Sie aufgehört haben",
  organisationen: "Organisationen",
  meine_aufgaben: "Meine Aufgaben",
  zu_meinen_aufgaben: "Zu meinen Aufgaben",
  zuletzt_verwendet: "Zuletzt verwendet",
  aufgaben: "Aufgaben",
  tabelle_anzeigen: "Tabelle anzeigen",
  suchen: "Suchen",
  umgesetzt: "Umgesetzt",
  vollstaendiges_konzept: "Vollständiges Konzept",
  historie_loeschroutinen: "Historie Löschroutinen",
  loeschroutine_erstellen: "Löschroutine erstellen",
  loeschroutine: "Löschroutine",
  benutzer: "Benutzer",
  relevanzpruefung: "Relevanzprüfung",
  risikoanalyse: "Risikoanalyse",
  ergebnis: "Ergebnis",
  erfassung_von_informationen_zur_relevanzpruefung: "Erfassung von Informationen zur Relevanzprüfung",
  gesetzlicheFragen: "Gesetzliche Fragen",
  weitereFragen: "Weitere Risikofragen",
  risikoszenarioErstellen: "Risikoszenario erstellen",
  risikoszenarien: "Risikoszenarien",
  risikoquelle: "Risikoquelle",
  risikoquellen: "Risikoquellen",
  rollen: "Rollen",
  keine_beschreibung: "Keine Beschreibung vorhanden.",
  rolle: "Rolle",
  zustaendig_personen: "Zuständige Personen",
  zustaendig_gruppen: "Zuständige Gruppen",
  schreibe_kommentar: "Schreibe einen Kommentar...",
  begruendungUndUrsacheDerRisikoquelle: "Begründung und Ursache der Risikoquelle",
  potenzielleSchaeden: "Potenzielle Schäden",
  eintrittswahrscheinlichkeit: "Eintrittswahrscheinlichkeit",
  schadensausmaß: "Schadensausmaß",
  Bitte_bestätige_zutreffende_Fragen_durch_die_Betätigung_der_Schaltfläche_und_füge_ggf_eine_Beschreibung_hinzu: "Bitte bestätigen Sie zutreffende Fragen durch die Betätigung der Schaltfläche und fügen Sie ggf. eine Beschreibung hinzu.",
  Bitte_bestätige_zutreffende_einschlägige_Fragen_durch_die_Betätigung_der_Schaltfläche_und_füge_ggf_eine_Beschreibung_hinzu: "Bitte bestätigen Sie zutreffende einschlägige Fragen durch die Betätigung der Schaltfläche und fügen Sie ggf. eine Beschreibung hinzu.",
  AusgewaehlteRisikoquellen: "Ausgewählte Risikoquellen",
  dsfa_erstellen: "Datenschutzfolgenabschätzung erstellen",
  risikoquelleErstellen: "Risikoquelle Erstellen",
  risikoquellenHinzufuegen: "Risikoquellen Hinzufügen",
  weiteresRisikosezenarioErfassen: "Weiteres Risikosezenario Erfassen",
  WeiterZurRisikoanalyse: "Weiter zur Risikoanalyse",
  RelevanzpruefungFortsetzen: "Relevanzprüfung Fortsetzen",
  EsIstEineDatenschutzfolgenabschaetzungErforderlich: "Es ist eine Datenschutzfolgenabschätzung erforderlich.",
  ErgebnisVorMaßnahmen: "Ergebnis vor Maßnahmen",
  ZusammenfassungDerRisiken: "Zusammenfassung der Risiken",
  ErgebnisNachMaßnahmen: "Ergebnis nach Maßnahmen",
  Ergebnis: "Ergebnis",
  risikoszenario: "Risikoszenario",
  bruttorisikowert: "Bruttorisikowert",
  brutto_risk_probability: "Bruttorisikowert der Eintrittswahrscheinlichkeit",
  brutto_risk_severity: "Bruttorisikowert des Schadensausmaßes",
  netto_risk_probability: "Nettorisikowert der Eintrittswahrscheinlichkeit",
  netto_risk_severity: "Nettorisikowert des Schadensausmaßes",
  netto_risk_scenario: "Nettorisikowert des Szenarios",
  nettorisikowert: "Nettorisikowert",
  nettowertBerechnen: "Nettorisikowert Berechnen",
  nameDesRisikoszenarios: "Name des Risikoszenarios",
  verarbeitungsorte: "Verarbeitungsorte",
  auswahlToms: "Wählen Sie eine oder mehrere TOM(s) aus",
  hinweisVonDerDigitalenRechtsassistenz: "Hinweis von der digitalen Rechtsassistenz",
  spezifische_angaben_zur_Verarbeitung: "Spezifische Angaben zur Verarbeitung",
  OeffneDiesesElementUmMöglicheHinweiseEinzusehen: "Öffnen Sie dieses Element, um mögliche Hinweise einzusehen",
  RisikobewertungOhneMaßnahmen: "Risikobewertung ohne Maßnahmen",
  bewertenSieDieAusgewaehltenToms: "Bewerten Sie die ausgewählten TOM(s)",
  EineRisikoanalyseIstNichtErforderlich: "Eine Risikoanalyse ist nicht erforderlich",
  DSFABeendenUndSpeichern: "DSFA beenden und speichern",
  aufgabeBearbeiten: "Aufgabe bearbeiten",
  frageBearbeiten: "Frage bearbeiten",
  themenkapitelBearbeiten: "Themenkapitel bearbeiten",
  zutreffend: "Zutreffend",
  fuegen_sie_eine_frage_hinzu: "Fügen Sie eine Frage hinzu",
  hinzufuegen_themenkapitel: "Fügen Sie ein Themenkapitel hinzu",
  hinzufuegen_themenkapitel_oder_frage: "Fügen Sie ein Themenkapitel oder eine Frage hinzu",
  themenkapitel_frage_auswaehlen: "Themenkapitel oder Frage auswählen",
  autor: "Autor",
  frage: "Frage",
  no_labels: "Keine Labels ausgewählt",
  no_logo: "Kein Logo vorhanden",
  zustaendig: "Zuständig",
  wiederkehrend: "Wiederkehrend",
  benutzerverwaltung: "Benutzerverwaltung",
  darstellung_sonstiges: "Darstellung und Sonstiges",
  auswertungen_und_berichte: "Auswertungen & Berichte",
  allgemeine_massnahmen: "Allgemeine Maßnahmen",
  massnahme: "Maßnahme",
  massnahmen: "Maßnahmen",
  verlinkung: "Verlinkung",
  verarbeitungstaetigkeiten: "Verarbeitungstätigkeiten",
  verarbeitungstaetigkeit: "Verarbeitungstätigkeit",
  unternehmen: "Unternehmen",
  datenschutzverletzungen: "Datenschutzverletzungen",
  filtern: "Filtern",
  kunden: "Klienten",
  risikowert: "Risikowert",
  abteilung_unabhängig: "Abteilungsunabhängige Ansicht von Objekten",
  betroffenenanfragen: "Betroffenenanfragen",
  AnzahlDerVerarbeitungstaetigkeiten: "Anzahl der Verarbeitungstätigkeiten",
  AnzahlVerarbeitungstaetigkeiten: "Anzahl Verarbeitungstätigkeiten",
  vertragsstatus: "Vertragsstatus",
  AnzahlDerDatenschutzfolgenabschaetzungen: "Anzahl der Datenschutzfolgenabschätzungen",
  AnzahlDerTechUndOrgMaßnahmen: "Anzahl der technischen und organisatorischen Maßnahmen",
  AnzahlDerAudits: "Anzahl der Audits",
  sonstiges: "Sonstiges",
  farbeWaehlen: "Farbe wählen",
  kopfzeilen: "Kopfzeile",
  fußzeilen: "Fußzeile",
  spaltenAnzahl: "Spaltenanzahl",
  spalteHinzufuegen: "Spalte hinzufügen",
  spalteEntfernen: "Spalte entfernen",
  spaltenHoehe: "Spaltenhöhe",
  textAbstandWaehlen: "Textabstand wählen",
  oben: "Oben",
  unten: "Unten",
  zurueck_dashboard: "Zurück zum Dashboard",
  AnzahlDerDatenschutzverletzungen: "Anzahl der Datenschutzverletzungen",
  AnzahlDerBetroffenenanfragen: "Anzahl der Betroffenenanfragen",
  AnzahlDerAufgaben: "Anzahl der Aufgaben",
  NeueDSFAErstellen: "Möchten Sie die neue DSFA zu einer bestehenden Verarbeitungstätigkeit erstellen?",
  erstellungEinerTom: "Erstellung einer technischen und organisatorischen Maßnahme",
  erstellungEinerDSFA: "Erstellung einer DSFA",
  erstellungEinerDatenpanne: "Erstellung einer Datenpanne",
  erstellungEinerExternenKommunikation: "Erstellung einer Externen Kommunikation",
  erstellungEinerCheckliste: "Erstellung einer Checkliste",
  erstellungEinerChecklisteAuswahl: "Anhand welcher bestehenden Vorlage möchten Sie eine Checkliste erstellen?",
  erstellungEinesAudit: "Erstellung eines Audit",
  erstellungEinesAuditAuswahl: "Anhand welcher bestehenden Vorlage möchten Sie ein Audit erstellen?",
  NeueVVTErstellen: "Möchten Sie die neue Vearbeitungstätigkeit anhand einer bestehenden Vorlage erstellen?",
  erstellungEinerVearbeitungstaetigkeit: "Erstellung einer Vearbeitungstätigkeit",
  NeueExterneKommunikationErstellen: "Möchten Sie eine Aufgabe erstellen, die sich auf die neu zu erstellende Externe Kommunikation bezieht?",
  NeueDatenpanneErstellen: "Möchten Sie eine Aufgabe erstellen, die sich auf die neu zu erstellende Datenpanne bezieht?",
  keine_risikoquellen: "Es wurden noch keine Risikoquellen erstellt.",
  keine_massnahmen: "Es wurden noch keine Maßnahmen ausgewählt.",
  keine_massnahmen_dsfa: "Keine Maßnahmen vorhanden.",
  esWurdenNochKeineMaßnahmenZurAbmilderungDesRisikosGetroffen: "Es wurden noch keine Maßnahmen zur Abmilderung des Risikos getroffen",
  erstellt_am: "Erstellt am",
  filter_zuruecksetzen: "Filter zurücksetzen",
  kleiner2: "< 2",
  groeßer2: "> 2",
  null_wort: "0",
  kleiner50: "< 50",
  kleiner70: "< 70",
  einhundertZahl: "100",
  geprueft: "Geprüft",
  offen: "Offen",
  geplant: "Geplant",
  scoringwert: "Scoringwert",
  gruppen: "Gruppen",
  gruppe: "Gruppe",
  nein: "Nein",
  bitte_auswahl: "Bitte treffen Sie zunächst eine Auswahl",
  ja_nein_frage: "Ja/Nein - Frage",
  freitext: "Freitext - Frage",
  einfachauswahl: "Einfachauswahl - Frage",
  mehrfachauswahl: "Mehrfachauswahl - Frage",
  max_upload_multiple: "Hinweis: Alle ausgewählten Dateien dürfen zusammen maximal 20 MB groß sein.",
  max_upload: "Hinweis: Die ausgewählte Datei darf maximal 20 MB groß sein.",
  bearbeitungshinweis: "Bearbeitungshinweis",
  beteiligteEmpfaenger: "Beteiligte Empfänger",
  WählenSieeinJahraus: "Wählen Sie ein Jahr aus",
  WählenSieeinQuartalaus: "Wählen Sie ein Quartal aus",
  WählenSieEinAbgeschlossenesAuditAus: "Wählen Sie ein abgeschlossenes Audit aus",
  allgemeineInformationen: "Allgemeine Informationen zu der Verarbeitungstätigkeit",
  dsfa_keine_informationen: "Es sind noch keine Informationen zur Verarbeitungstätigkeit vorhanden, bitte vervollständigen Sie diese.",
  informationen_erstellen: "Informationen erstellen",
  InformationenBearbeiten: "Informationen bearbeiten",
  pdf_vorlagen: "PDF Vorlagen",
  BearbeitungDerBenötigtenInformationen: "Bearbeitung der benötigten Informationen",
  response_400_measure: "Diese Maßnahme ist dem Unternehmen bereits zugeordnet.",
  response_400_ica_on_behalf: "Die ausgewählte Verarbeitung im Auftrag ist bereits mit einem anderen Intercompany Agreement verknüpft.",
  response_400_inheritance: "Dieses Objekt wurde bereits an das Zielunternehmen vererbt.",
  response_400_subcontractor: "Der Unterauftragnehmer ist für diesen Datenempfänger bereits hinterlegt.",
  response_400_resource_location: "Es existiert bereits ein Ort mit diesen Daten.",
  response_400_storage_location: "Dieser Verarbeitungsort besteht für diesen Datenempfänger bereits.",
  response_400_company_role: "Diese Rolle ist als Standardrolle für das Unternehmen definiert und kann daher nicht entfernt werden.",
  http_413: "Upload fehlgeschlagen. Die Dateigröße von 20 MB wurde überschritten.",
  standard_template: "Standardvorlage",
  standard_resource: "Standardressource",
  MI: "Minuten",
  HO: "Stunden",
  anschrift: "Anschrift",
  zustaendige_personen: "Zuständige Personen",
  DA: "Tage",
  WE: "Wochen",
  MO: "Monate",
  YE: "Jahre",
  DA_single: "Tag",
  WE_single: "Woche",
  MO_single: "Monat",
  YE_single: "Jahr",
  beschreibung_kategorien_verarbeitungen: "Beschreibung der Kategorien von Verarbeitungen",
  pa_1: "Der Name der Verarbeitungstätigkeit dient gleichzeitig als Titel der Verarbeitung für alle Übersichten (z.B. Verzeichnis der Verarbeitungstätigkeiten, Löschkonzept) innerhalb des DSMS. Es ist zu empfehlen, dass der Name der Verarbeitung unabhängig von einzelnen IT-Tools sein sollte, die ggfs. im Rahmen der Verarbeitung zum Einsatz kommen.",
  pa_2: "Wähle hier die zuständigen Personen aus, welche die Prozessverantwortlichkeit für diese Verarbeitung besitzen. Beachte, dass die erste Person auch automatisch im Löschkonzept für die Verarbeitung eingetragen wird.",
  pa_3: "Wählen Sie hier die zuständigen Abteilungen aus dem Unternehmen aus.",
  pa_4: "Beschreiben Sie hier die Verarbeitungsvorgänge im Detail unter Berücksichtigung der Zwecke der Verarbeitungstätigkeit.",
  pa_5: "Wählen Sie hier die einschlägigen Zwecke für die Verarbeitungstätigkeit gem. Art. 30. Abs. 1 lit. b) DSGVO aus.",
  pa_6: "Jede Verarbeitungstätigkeit muss durch eine Rechtsgrundlage legitimiert werden. Wählen Sie hier die einschlägige Rechtsgrundlage gem. Art. 6 DSGVO aus.",
  pa_7: "Wählen Sie die zutreffenden Kategorien der personenbezogenen Daten der Verarbeitungstätigkeit aus gem. Art. 30 Abs. 1 lit. c) DSGVO. Beachten Sie, dass die Auswahl der Kategorien einen Einfluss auf die Risikobewertung der Verarbeitung haben.",
  pa_8: "Wählen Sie die zutreffenden besonderen Kategorien der personenbezogenen Daten der Verarbeitungstätigkeit aus gem. Art. 30 Abs. 1 lit. c) DSGVO. Beachten Sie, dass die Auswahl der Kategorien einen Einfluss auf die Risikobewertung der Verarbeitung haben.",
  pa_9: "Wählen Sie die zutreffenden Kategorien der betroffenen Personengruppen der Verarbeitungstätigkeit aus gem. Art. 30 Abs. 1 lit. c) DSGVO . Beachten Sie, dass die Auswahl der Kategorien einen Einfluss auf die Risikobewertung der Verarbeitung haben.",
  pa_10: "Wählen Sie die beteiligten internen Empfänger (Abteilungen) der Verarbeitungstätigkeit aus.",
  pa_11: "Wählen Sie die beteiligten externen Empfänger (Datenempfänger, i.d.R. Auftragsverarbeiter) der Verarbeitungstätigkeit aus. Beachten Sie, dass die Auswahl der externen Empfänger einen Einfluss auf die Risikobewertung der Verarbeitung haben.",
  pa_11_kategorien_empfaenger: "Wählen Sie die beteiligten Kategorien der Empfänger der Verarbeitungstätigkeit aus.",
  pa_12: "Ordnen Sie jeder Kategorie von personenbezogenen Daten die jeweiligen Löschfristen zu gem. Art. 30 Abs. 1 lit. f) DSGVO. Beachten Sie, dass diese Zuordnung vom DSMS als Grundlage für das Löschkonzept verwendet wird. Die Löschung der Datenkategorien gem. der Löschfristen muss erst nach Zweckentfall erfolgen.",
  pa_13: "Treffen Sie spezifische technische und organisatorische Maßnahmen gem. Art. 30 Abs. 1 lit. g) i.V.m. Art. 32 Abs. 1 DSGVO, um das Risiko der Verarbeitungstätigkeit zu minimieren.",
  pa_14: "Bewerten Sie die allgemeinen technischen und organisatorischen Maßnahmen gem. Art. 30 Abs. 1 lit. g) i.V.m. Art. 32 Abs. 1 DSGVO, um das Risiko der Verarbeitungstätigkeit zu minimieren.",
  pa_15: "Die Bewertung der Verarbeitungstätigkeit basiert auf der Auswertung aller Angaben in den vorherigen Schritten. Die Bewertung des Risikos kann bereits als Orientierung herangezogen werden, um zu bewerten, ob die Durchführung einer Datenschutz-Folgenabschätzung (DSFA) durchgeführt werden sollte. Beachten Sie, dass die Verarbeitungstätigkeit bereits in eine DSFA überführt werden kann, sodass eine effiziente Weiterverarbeitung gewährleistet wird.",
  pa_16: "Bewerten Sie mit dem Risiko, wie sensibel das Datum ist. Umso sensibler das Datum umso höher das Risiko.",
  pa_17: "Wählen Sie an dieser Stelle aus, ob eine Datenübertragung in ein Drittland stattfindet. Wenn externe Empfänger ausgewählt wurden, welche ihren Sitz oder Speicherorte in einem Drittland haben, wird die Drittlandübermittlung automatisiert ausgewertet.",
  pa_18: "Beschreiben Sie an dieser Stelle die Kategorien von Verarbeitungen, welche im Auftrag durchgeführt werden.",
  Info_P2: "Bei dieser Verarbeitung sollen Daten, welche einer besonderen Vertraulichkeit unterliegen (z.B. Berufsgeheimnis), verarbeitet werden. Andere Organisationen bewerten daher das Schadensausmaß als hoch und treffen Maßnahmen zur Anonymisierung, Reduzierung und Verschlüsselung der Datensätze.",
  Info_P5: "Diese Verarbeitung besteht aus einer systematischen umfangreichen Überwachung öffentlich zugänglicher Bereiche. Andere Organisationen bewerten daher die Eintrittswahrscheinlichkeit als hoch und treffen Maßnahmen gegen die unerlaubte Offenlegung dieser Daten, indem Berechtigungskonzepte und Löschkonzepte implementiert werden.",
  Info_O10: "Bei dieser Verarbeitung sollen Daten von besonders schützenswerten Personen (z.B. Kinder, Beschäftigte) verarbeitet werden. Andere Organisationen bewerten daher das Schadensausmaß als hoch und treffen Maßnahmen zur Anonymisierung oder Pseudonymisierung.",
  Info_P1_11_13_14_O5: "Bei dieser Verarbeitung sollen besondere Kategorien von personenbezogenen Daten verarbeitet werden. Andere Organisationen bewerten daher das Schadensausmaß als hoch und treffen Maßnahmen zur Anonymisierung und Reduzierung der Datensätze.",
  Info_P9_10_14_15_O9: "Diese Verarbeitung verwendet innovative Technologien. Andere Organisationen bewerten die Eintrittswahrscheinlichkeit als hoch und treffen Implementierungsmaßnahmen für Testsysteme und Testzyklen, sowie eine automatische Überwachung der Technologien zur Eindämung der Eintrittswahrscheinlichkeit.",
  Info_P6_7_8_12_O11: "Diese Verarbeitung besteht aus der Erfassung von Daten, welche zur Bewertung von Verhaltensweisen von natürlichen Personen verwendet werden können. Andere Organisationen bewerten das Schadensausmaß als hoch und treffen Maßnahmen zur Pseudonymisierung und Verschlüsselung der Datensätze.",
  Info_O12_14: "Es werden Datenempfänger eingesetzt, welche von einer Instanz eines Drittlands die Pflicht auferlegt bekommen können, diese Daten entgegen Art. 48 DSGVO zu exportieren oder offenzulegen. Andere Organisationen bewerten die Eintrittswahrscheinlichkeit als hoch und treffen Maßnahmen zur Verschlüsselung der Daten zum Schutz vor Zugriffen der Datenempfänger oder Instanzen aus den Drittländern.",
  kein_drittland: "Es erfolgt keine Datenübermittlung in ein Drittland.",
  sicheres_drittland: "Sicheres Drittland",
  unsicher_drittland: "Es erfolgt eine Datenübermittlung in ein Drittland.",
  sicher_drittland: "Es erfolgt eine Datenübermittlung in ein sicheres Drittland (Angemessenheitsbeschluss).",
  measure_risk_0: "Unwirksam",
  fehler_export: "Beim Export ist leider ein Fehler aufgetreten. Versuchen Sie es später bitte erneut",
  measure_risk_1: "Geringe Wirksamkeit",
  conformity_result: "Die Konformität des gesamten Audits beläuft sich auf",
  measure_risk_2: "Normale Wirksamkeit",
  measure_risk_3: "Hohe Wirksamkeit",
  measure_risk_4: "Sehr hohe Wirksamkeit",
  maßnahmeVertraulichkeit: "Maßnahmen zur Gewährleistung der Vertraulichkeit",
  erfolgreich_speichern: "Erfolgreich gespeichert",
  fehler_speichern: "Fehler beim Speichern",
  nicht_erforderlich: "Nicht erforderlich",
  erfolgreich_umgesetzt: "Erfolgreich umgesetzt",
  nicht_umgesetzt: "Nicht umgesetzt",
  aufbewahrungsorte: "Aufbewahrungsorte",
  ausgewaehlt_elemente: "Ausgewählte Elemente",
  mehr_anzeigen: "Mehr anzeigen",
  typ: "Typ",
  alle_auswaehlen: "Alle auswählen",
  nicht_speichern_wegen_standard: "Bei dieser Resource handelt es sich um eine Standardresource. Daher kann diese nicht bearbeitet und verändert werden.",
  error_deleted_user: "Ein gelöschter Benutzer darf nicht ausgewählt werden.",
  weitere_fragen: "Weitere Risikofragen",
  informationen_erzeugt_durch_Verarbeitungstätigkeit: "Dieser Bereich zeigt Informationen an, welche durch die Bearbeitung von dem Verzeichnis von Verarbeitungstätigkeiten erzeugt werden.",
  informationen_erzeugt_durch_Verarbeitungstätigkeit_erasure: "Dieser Bereich zeigt Informationen an, welche durch die Bearbeitung von dem Verzeichnis von Verarbeitungstätigkeiten erzeugt werden. Die Löschung der Datenkategorien gem. der Löschfristen muss erst nach Zweckentfall erfolgen.",
  inhalte: "Inhalte",
  anzahl: "Anzahl",
  stundenanzahl: "Stundenanzahl",
  einheit: "Einheit",
  keine_benachrichtigungen: "Keine Benachrichtigungen",
  keine_quellen: "Keine Risikoquellen ausgewählt.",
  kein_zugriff: "Sie haben auf dieses Unternehmen keinen Zugriff.",
  personen: "Personen",
  massnahmen_erstellen: "Alle Maßnahmen für das Unternehmen erstellen",
  alle_massnahmen_erstellen: "Möchten Sie wirklich alle Maßnahmen für das Unternehmen erstellen",
  verarbeitung_erstellen: "Verarbeitungstätigkeit erstellen",
  verarbeitungs_wirklich_erstellen: "Möchten Sie aus diesen Informationen wirklich eine Verarbeitungstätigkeit erstellen?",
  verarbeitung_dsfa: "Datenschutzfolgenabschätzung öffnen",
  zur_verarbeitung: "Verarbeitungstätigkeit öffnen",
  kategorien_vererbung: "Die Kategorien {categories} wurden vererbt und können nicht entfernt oder bearbeitet werden.",
  aufgaben_benachrichtigung: "Sie wurden der Aufgabe {name} zugewiesen",
  export_benachrichtigung: "{name} herunterladen",
  dsfa_risiko_ergebnis: "Vor Maßnahmen ergibt sich {brutto}. Die Maßnahmen verringern das Risiko auf {netto}.",
  vorname: "Vorname",
  nachname: "Nachname",
  pdf: "PDF",
  word: "Word",
  word_vorlagen: "Word Vorlagen",
  word_vorlage: "Word Vorlage",
  herunterladen: "Herunterladen",
  export_vorlagen: "Export-Vorlagen",
  tom_hinweis: "An dieser Stelle werden ausschließlich ausgewählte technische und organisatorische Maßnahmen dieser Verarbeitungstätigkeit genannt. Allgemeine technische und organisatorische Maßnahmen finden Sie in der <a class='measures'>TOM-Übersicht</a>.",
  tom_hinweis_im_auftrag: "Eine Übersicht der allgemeinen technischen und organisatorischen Maßnahmen finden Sie in der <a class='measures'>TOM-Übersicht</a>.",
  erasure_concept_hint: "In unserem <a class='erasure'>Löschkonzept</a> finden Sie weitere Informationen zu unseren Löschfristen und Aufbewahrungsorten.",
  interne_empfaenger: "Interne Empfänger",
  externe_empfaenger: "Externe Empfänger",
  keine_externen_datenempfeanger: "Keine externen Datenempfänger",
  keine_kategorien_der_empfaenger: "Keine Kategorie(n) der Empfänger",
  hinweis: "Hinweis",
  receiver_location_hint: "Der Sitz des Empfängers liegt im Land {country}. Dieses Land ist nicht Teil des europäischen Wirtschaftsraums und es besteht kein Angemessenheitsbeschluss.",
  receiver_location_secure_hint: "Der Sitz des Empfängers liegt im Land {country}. Es handelt sich um ein Drittland mit einem Angemessenheitsbeschluss.",
  erlaeuterung: "Erläuterung",
  farben: "Farben",
  primaer: "Primär",
  appleiste_hintergrund: "Hintergrund App-Leiste",
  hintergrund: "Hintergrund",
  fehler: "Fehler",
  warnung: "Warnung",
  erfolg: "Erfolg",
  info: "Information",
  informationen: "Informationen",
  berechtigungen: "Berechtigungen",
  ansehen: "Ansehen",
  bearbeiten: "Bearbeiten",
  gewichtung: "Gewichtung",
  gewichtungWaehlen: "Wählen Sie eine Gewichtung zwischen 0 und 10",
  erstellen_gross: "Erstellen",
  create_checklist_question_info: "Hinweise zur Erstellung von Fragen",
  create_checklist_question_hint: "Erstellen Sie die Frage so, dass diese mit Ja oder Nein beantwortet werden kann",
  create_checklist_question_hint_freetext: "Stellen Sie die Frage so, dass die Antwort individuell formuliert werden kann.",
  create_checklist_question_hint_singleselect: "Stellen Sie die Frage so, dass nur eine Antwort einschlägig ist.",
  create_checklist_question_hint_multiselect: "Stellen Sie die Frage so, dass mehrere Antworten zutreffen können.",
  question_hint_general: "Geben Sie zunächst den Fragetyp an, um hier weitere Informationen zur Beantwortung der Frage zu erhalten.",
  weitere_info_risiko: "Weitere Hinweise zur Risikoberechnung",
  risk_chart_hint: "Das Risiko wird jeweils als Bruttorisiko (vor Maßnahmen) und als Nettorisiko (nach Maßnahmen) dargestellt. Das Bruttorisiko besteht aus dem Schadensausmaß und der Eintrittswahrscheinlichkeit einer Verletzung des Schutzes personenbezogener Daten. Die Berechnung des Schadensausmaßes wird aus den Angaben der Kategorien personenbezogener Daten, den Kategorien der Betroffenen und der Datenübertragung in ein Drittland vorgenommen. Zur Berechnung der Eintrittswahrscheinlichkeit werden die Angaben zur Datenübertragung in ein Drittland und der Löschfristen herangezogen. Alle Angaben, welche zur Auswahl stehen, enthalten Risikowerte und Gewichtungen für die Beeinflussung des Risikos. Das Bruttorisiko wird abschließend durch die ausgewählten technischen und organisatorischen Maßnahmen (TOM) minimiert und als Nettorisiko dargestellt.",
  riskchart: {
    bruttorisiko: "Bruttorisiko (ohne Maßnahmen)",
    nettorisiko: "Nettorisiko (abzgl. Maßnahmen)",
  },
  rules: {
    empty_field: "Dieses Feld darf nicht leer sein.",
    name_max_length: "Die Eingabe darf maximal 255 Zeichen lang sein.",
    integer_rule: "Es dürfen nur Ganzzahlen ohne Trennzeichen verwendet werden",
  },
  einblenden: "einblenden",
  vererbung: "Vererbung",
  jahresbericht: "Jahresbericht",
  jahresbericht_erklaerung: "Der Jahresbericht ist ein gutes Instrument, um die Aktivitäten und Veränderungen des vergangenen Zeitraums im Datenschutz kompakt darzustellen. Wichtige Kennzahlen geben hierbei eine Indikation für das Management.",
  ja: "Ja",
  themenkapitel: "Themenkapitel ",
  jahresberichtErstellen: "Jahresbericht erstellen",
  auditberichtErstellen: "Auditbericht erstellen",
  auditbericht: "Auditbericht",
  auditbericht_erklaerung: "Der Auditbericht stellt die Ergebnisse eines ausgewählten Audits zusammenfassend dar und dient als Grundlage zur Besprechung eines Audits.",
  bericht_erstellen: "Bericht erstellen",
  impact_assessment_recommendation: "Wir empfehlen Ihnen die Durchführung einer Datenschutzfolgendabschätzung für diese Verarbeitungstätigkeit.",
  critical_country: 'Das Land {country} ist nicht Teil des europäischen Wirtschaftsraums und es besteht kein Angemessenheitsbeschluss.',
  critical_countries: 'Die Länder {countries} sind nicht Teil des europäischen Wirtschaftsraums und es besteht kein Angemessenheitsbeschluss.',
  secure_country: 'Für das Land {country} besteht ein Angemessenheitsbeschluss.',
  data_receiver_warning_country: 'Dieses Land ist nicht Teil des europäischen Wirtschaftsraums.',
  data_receiver_secure_country: 'Dieses Land ist nicht Teil des europäischen Wirtschaftsraums, jedoch besteht aktuell ein Angemessenheitsbeschluss.',
  secure_countries: 'Für die Länder {countries} besteht ein Angemessenheitsbeschluss.',
  total_risk: {
    netto_simple: "(Netto) Gesamtrisiko",
    simple: "Gesamtrisiko",
    impact_assessment: "Die Dateschutzfolgeabschätzung hat voraussichtlich {risk}.",
    processing_ctivity: "Die Verarbeitungstätigkeit hat voraussichtlich {risk}.",
    total_risk_0: "kein Risiko",
    total_risk_1: "ein geringes Gesamtrisiko",
    total_risk_2: "ein mittleres Gesamtrisiko",
    total_risk_3: "ein hohes Gesamtrisiko",
    total_risk_4: "ein sehr hohes Gesamtrisiko",
  },
  kein_risiko: "Kein Risiko",
  sehr_hohes_risiko: "Sehr hohes Risiko",
  hinzu: "hinzu",
  leistungsbeschreibung_aendern: "Ändere die Leistungsbeschreibung für den Subunternehmer",
  leistungsbeschreibung_hinzufuegen: "Füge eine Leistungsbeschreibung für den Subunternehmer",
  risk_0: "Kein Risiko",
  risk_1: "Gering",
  risk_2: "Mittel",
  risk_3: "Hoch",
  risk_4: "Sehr Hoch",
  receiver_type_DTL: "Dienstleister",
  receiver_type_GMV: "Gemeinsam Verantwortlich",
  receiver_type_ATV: "Auftragsverarbeiter",
  receiver_type_SON: "Sonstige",
  receiver_type_ICA: "Intercompany Agreement",
  no_documents: "Keine verknüpften Dokumente",
  no_audits: "Keine verknüpften Audits",
  datenschutzerklaerungen: "Datenschutzerklärungen ",
  datenschutzerklaerungen_text: "Um die Informationspflichten aus Art. 12 ff. DSGVO zu erfüllen, können hier Schritt-für-Schritt Datenschutzerklärungen erstellt werden für verschiedene Zwecke.",
  vorlage_auswaehlen: "Vorlage auswählen",
  vorlage_auswaehlen_tabelle: "Wählen Sie eine Vorlage aus",
  hinweis_vererbte_verarbeitung: "Sie bearbeiten eine vererbte Verarbeitung. Die Bearbeitungsfelder sind leer, damit Sie sehen, welche Inhalte Sie überschreiben. Die bereits vererbten Inhalte, sehen Sie auf der Detailansicht.",
  verknuepfte_aufgabe_anlegen: "Möchten Sie zu dieser Dokumentation eine verknüpfte Aufgaben erstellen?",
  response_400_erasure_routine: "Die Löschfrist ist für die Erstellung einer Aufgabe zu kurz. Es muss mindestens die Einheit \"Tage\" verwendet werden.",
  verarbeitung_oeffnen: "Verarbeitungstätigkeit öffnen",
  exportiert: "Der Export wird im Hintergrund erzeugt. Du erhältst eine Benachrichtigung, sobald der Download bereitsteht.",
  response_400_processing_activity_impact_assessment: "Zu der ausgewählten Verarbeitungstätigkeit existiert bereits eine Datenschutzfolgenabschätzung.",
  response_400_error_user_exists: "Ein Benutzer mit dieser E-Mail-Adresse existiert in diesem Unternehmen bereits.",
  audit_vorlage_erstellen: "Auditvorlage erstellen",
  smartes_audit: "Soll die Vorlage für ein smartes Audit erstellt werden?",
  audit_art: "Art des Audits",
  confirm_intercompany_agreement: "Intercompany Agreement bestätigen",
  intercompany_agreement: "Intercompany Agreement",
  auftragsverarbeitung: "Auftragsverarbeitung",
  beteiligte_unternehmen: "Beteiligte Unternehmen",
  bestaetigung: "Bestätigung",
  intercompany_agreement_delete_company: "Beteiligtes Unternehmen entfernen",
  intercompany_agreement_add_company: "Beteiligtes Unternehmen hinzufügen",
  unternehmen_hinzufuegen: "Unternehmen hinzufügen",
  confirmation_intercompany_agreement: "Am {confirmed_at} bestätigt durch {forename} {surname}",
  nicht_bestaetigt: "Keine Bestätigung",
  bestaetigt: "Bestätigt",
  is_vvt: "Verarbeitung im Auftrag?",
  um: "um",
  info_ica: "Sofern Sie als Auftragsverarbeiter i.S.d. Art. 4 Nr. 8 DSGVO im Rahmen eines Intercompany Agreement tätig sind, können Sie durch diese Auswahl im folgenden Schritt die zutreffenden Vertragsbeziehungen zuordnen.",
  vertrag_herunterladen: "Vertrag herunterladen",
  du_kannst: "Du kannst",
  hier: "hier",
  dsfa_erstellen_pa: "eine Datenschutzfolgenabschätzung zu dieser Verarbeitungstätigkeit erstellen.",
  interne_auftraggeber: "Interne Auftraggeber",
  ica_officer: "Zuständiger Benutzer für Intercompany Agreements",
  ica_upload: "Das Hochladen einer neuen Vertragsdatei überschreibt die aktuelle Datei unwiderruflich.",
  ica_upload_upfront_confirmed: "Es werden alle bisherigen Bestätigungen der Unternehmen zurückgesetzt.",
  confirmed_successfully: "Erfolgreich bestätigt",
  upfront_confirmed: "Wurde der Vertrag bereits von allen (zukünftig) hinterlegten Unternehmen unterzeichnet?",
  neuen_vertrag_hochladen: "Neuen Vertrag hochladen",
  vertrag_hochladen: "Vertrag hochladen",
  upfront_confirmed_info: "Wenn diese Option aktiviert wird, wird das Intercompany Agreement für alle bereits hinterlegten und zukünftig hinzugefügten Unternehmen automatisch bestätigt. Sie können diese Option jederzeit deaktivieren, sodass alle Unternehmen zu einer Bestätigung aufgefordert werden.",
  ica_upfront_confirmed_hint: "Es handelt sich um ein bereits unterzeichnetes Intercompany Agreement, daher ist die Verwaltung der Bestätigungen nicht verfügbar.",
  drittenBeteiligteVorhanden: "Es sind dritte beteiligt",
  receiver_contracts_ica_prepand: "Dieser Datenempfänger wurde über ein Intercompany Agreement erzeugt, daher können keine separaten Verträge hinterlegt werden. Sie können die Verträge im ",
  receiver_contracts_ica_append: " einsehen.",
  ip_message: "Sie haben versucht, von einer nicht autorisierten IP-Adresse ein Unternehmen mit beschränktem Zugriff zu öffnen.",
  checkbox_ica: "Hiermit bestätige ich, dass ich den Vertrag gelesen habe und alle Bestandteile akzeptiere.",
  smart_audit_end: "Sie haben alle Fragen erfolgreich beantwortet. Rechts wird Ihnen das Ergebnis angezeigt.",
  next_question: "Nächste Frage",
  prev_question: "Vorherige Frage",
  kopieren: "Kopieren",
  zentrale_benutzerverwaltung: "Zentrale Benutzerverwaltung",
  benutzereinstellungen: "Benutzereinstellungen",
  daten_init: "Daten vervollständigen",
  speichern_abmelden: "Speichern und abmelden",
  oeffnen: "Öffnen",
  benutzer_erstellen: "Benutzer erstellen",
  benutzer_bearbeiten: "Benutzer bearbeiten",
  benutzer_loeschen: "Benutzer entfernen",
  auswahl_loeschen: "Auswahl löschen",
  csv_hochladen: "CSV hochladen",
  intercompany_beauftragter: "Beauftragter für Intercompany Agreement",
  hinweis_tochterunternehmen_inkludiert: "Es werden nur die Tochterunternehmen eingeschlossen, auf welche Sie selbst Zugriff haben.",
  tochtunternehmen_inkludieren: "Sollen alle Tochterunternehmen der ausgewählten Unternehmen ebenfalls mit eingeschlossen werden?",
  hinweis_vererbte_datenempfänger_nicht_angezeigt: "Vererbte Datenempfänger werden in diesem Auswahldialog nicht angezeigt, wenn die Verarbeitungstätigkeit bereits an ein anderes Unternehmen vererbt wurde. Dies liegt daran, dass vererbte Datenempfänger nicht weitervererbt werden dürfen.",
  $vuetify: {
    ...de,
  },
};
