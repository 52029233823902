import { en } from "vuetify/lib/locale";

export default {
  einstellungen: "Settings",
  dsfa_vorlage: "Would you like to use a template for creating the data protection impact assessment?",
  benachrichtigung: "Notification",
  benachrichtigungen: "Notifications",
  benachrichtigungenEmail: "Notifications by email",
  benachrichtigungBetroffenen: "Notification of data subjects",
  passwortzuruecksetzen: "Reset password",
  created_info: "Details",
  persoenlicheEinstellungen: "Personal settings",
  WollenSieWeiterhinBenachrichtigungenErhalten: "Do you want to continue receiving notifications?",
  abmelden: "Logout",
  uebersicht: "Overview",
  vererbung_bestaetigen: "Confirm inheritance",
  risiken: "Risks",
  sprache: "Language",
  hinzu: "",
  leistungsbeschreibung_aendern: "Change the service description for the subcontractor",
  leistungsbeschreibung_hinzufuegen: "Add a service description for the subcontractor",
  inherited_receiver_included: "You cannot inherit a processing activity in which an already inherited data recipient is used.",
  zentrale_benutzerverwaltung: "Central Usermanagement",
  intercompany_agreement: "Intercompany Agreement",
  keine_szenarien: "No scenarios",
  keineDatenkategorienVorhanden: "No data categories have been selected yet. Select data categories under step 2 to create deletion periods afterwards.",
  szenario: "Scenario",
  verarbeitung_sicheres_drittland: "Processing in a secure third country?",
  verarbeitung_eu: "Processing in the EU?",
  fehler_export: "Unfortunately, an error occurred during export. Please try again later.",
  ersteller: "Creator",
  auftragnehmer: "contractor",
  organisation_verwalten: "Manage organisation",
  organisation_oeffnen: "Open organisation",
  unternehmensstruktur: "Companystructure",
  ressourcen: "Resources",
  vorlagen: "Templates",
  beispiel: "This is a template text. Lorem Ipsum",
  aufgabenuebersicht: "Overview of tasks",
  filter_loeschen: "Clear all",
  datenempfaenger: "Recipient",
  dokumentationen: "Documentation",
  is_vvt: "Is processing on behalf?",
  vererbung_erfolgreich: "Inheritance is performed asynchronously in the background. This can take some time depending on the amount of data.",
  vvt: "Records of processing activities",
  vvt_sha: "Records of pro&shy;cessing activi&shy;ties",
  verzeichnisVerabeitungenImAuftrag: "Records of processing activities as a processor",
  verabeitungenImAuftrag: "Processing for other controllers",
  organisation_wechseln: "Change organisation",
  generator: "Document generator",
  softwareloesungen: "Softwaresolutions",
  auftraggeber: "Customer",
  organisationseinstellungen: "Settings of organisation",
  stammdaten: "Master data",
  intercompany_beauftragter: "Representative for Intercompany Agreement",
  abbrechen: "Cancel",
  benachrichtigung_erfolgt: "Notification has been sent?",
  alle_auswaehlen: "Select all",
  schließen: "Close",
  speichern: "Save",
  vererbt: "Inherited ?",
  vererbt_bekommen: "Inherited?",
  geschaeftsfuehrer: "CEO",
  ip_message: "You have tried to open a company with restricted access from an unauthorized IP address.",
  speichernUndZurAnsicht: "Save and back to overview",
  firma: "Company",
  confirm_intercompany_agreement: "Confirm Intercompany Agreement",
  intercompany_agreement_add_company: "Add company involved",
  intercompany_agreement_delete_company: "Remove involved company",
  branche: "Industry",
  oeffnen: "Open",
  ausblenden: "hide",
  ausfuellhinweise: "Completion instructions",
  strasse: "Street",
  kunden: "Clients",
  hausnummer: "Number",
  plz: "postcode",
  ort: "Location",
  orte: "Locations",
  kein_risiko: "No risk",
  geringes_risiko: "Low Risk",
  mittleres_risiko: "Medium Risk",
  hohes_risiko: "High Risk",
  sehr_hohes_risiko: "Very high Risk",
  auswertungen: "Evaluations",
  auftragsverarbeitung: "Contract Processing",
  berichte: "Reports",
  aufsichtsbehoerden: "Supervisory Authorities",
  aufsichtsbehoerde: "Supervisory Authority",
  land: "Country",
  datenuebertragung_in_drittland: "Data transfer to a third country",
  email: "E-Mail",
  telefon: "Telephone",
  webseite: "Website",
  datenpannen: "Data breach",
  dskoordinator: "Privacy coordinator",
  dsb: "Data protection officer",
  stammdaten_bearbeiten: "Edit master data",
  unternehmen_erstellen: "Create company",
  keine_daten: "No data available",
  keine_dateien: "No data available",
  dateien: "data",
  Dateien: "Data",
  hochladen: "upload",
  autocomplete_suche: "Input to search ...",
  erstellen: "Create",
  abteilung: "Department",
  abteilungen: "Departments",
  upload_text_multiple: "Move files here or click here to select files.",
  upload_text: "Move files here or click here to select files.",
  upload_word: "Move Word-files here or click here to select Word-files.",
  ausgewaehlte_dateien: "Selected files",
  ausgewaehlte_datei: "Selected file",
  name: "Name",
  details: "Details",
  verantwortliche: "Controller",
  tage: "Days",
  tag: "Day",
  uhr: "",
  farbe: "Color",
  bis: "until",
  bisZum: "until to",
  zeitraum: "Time period",
  zeitpunkt: "Time",
  wochen: "Weeks",
  woche: "Week",
  ende_am: "End at",
  monate: "Months",
  monat: "Month",
  wiederholung_alle: "Repeat every",
  jahre: "Years",
  jahr: "Year",
  entfernen: "Delete",
  loeschen: "Delete",
  loeschenAusDerGruppe: "Delete from the group",
  wiederholungsende: "End of repetition",
  profilsprache: "Language of profile",
  diesen_kommentar: "this comment",
  task_1: "Configure a recurring task here. You can find more information in our FAQ.",
  ansprechpartner: "Contact persons",
  tom: "Technical and organisational measures",
  tom_sha: "Technical and orga&shy;nisational mea&shy;sures",
  loeschkonzept: "Deletion concept",
  prioritaet: "Priority",
  priority_0: "Low",
  priority_1: "Medium",
  priority_2: "High",
  loeschen_1: "Do you want to",
  ersatzbenutzer: "Replacement user",
  hinweis_benutzer_loeschen: "The user remains for 30 days, but can no longer be selected. After the 30 days have expired, the user will be permanently removed.",
  diese_stunden: "these hours",
  loeschen_2: "finally delete",
  dsfa: "Data protection impact assessment",
  dsfa_sha: "Data protec&shy;tion impact assess&shy;ment",
  externe_kommunikation: "External communication",
  externe_kommunikation_sha: "External com&shy;munication",
  auditsUndChecklisten: "Audits & Checklists",
  audits: "Audits",
  audit: "Audit",
  audit_company: "Audit of company",
  audit_datareceiver: "Audit of recipient",
  checklisten: "Checklists",
  maßnahme: "Measure",
  art: "Type",
  startdatum: "Date of beginning",
  enddatum: "Date of ending",
  datum: "Date",
  eingangsdatum: "Date of receipt",
  datumBeginn: "Date of beginning",
  uhrzeitBeginn: "Time of beginning",
  datumEnde: "Date of ending",
  uhrzeitEnde: "Time of ending",
  bestaetigung: "Confirmation",
  verknuepfte_aufgabe_anlegen: "Would you like to create a linked task for this documentation?",
  smart_audit_end: "You have successfully answered all questions. The result is displayed on the right.",
  smartes_audit: "Should the template be created for a smart audit?",
  audit_vorlage_erstellen: "create audit template",
  audit_art: "Type of the Audit",
  verarbeitung_oeffnen: "Open processing activity",
  next_question: "Next question",
  prev_question: "Previous question",
  kopieren: "Copy",
  vertrag_herunterladen: "Download contract",
  unternehmen_hinzufuegen: "Add company",
  beteiligte_unternehmen: "Involved companies",
  vertrag: "Contract",
  uhrzeit: "Time",
  heute: "Today",
  stunden: "Hours",
  zeit_erfassen: "Recording time",
  zeiterfassung: "Time recording",
  erstelldatum: "Date of creation",
  dokument_erstellen: "Create document",
  bezugderMaßnahme: "Reference to the measure",
  frist: "Deadline",
  benutzer_erstellen: "Create User",
  benutzer_bearbeiten: "Edit User",
  benutzer_loeschen: "Remove User",
  bezeichnung: "Designation",
  benachrichtigungspflicht: "Obligation to notify",
  kommentare: "Comments",
  letzteBearbeitung: "Last edit",
  vergangene_pruefungen: "Past audits",
  wiederkehrende_pruefungen: "Periodic audits",
  pruefungen_konfigurieren: "Configure audit",
  keine_wiederkehrung: "No periodic audits have yet been configured for this data recipient.",
  bearbeitung: "Editing",
  blockierte_ressource: "This object is currently being edited by {vorname} {nachname}",
  status: "Status",
  StatusDerPruefungen: "Status of audits",
  keine_Tags_vorhanden: "No tags available",
  funktion_nicht_vorhanden: "This function is not available in the demo.",
  vorabeinschätzungDurchfuehrenText: "Would you like to carry out a preliminary assessment in order to obtain a recommendation as to whether a report to the supervisory authority or notification of the data subjects is necessary?",
  neueDatenpanne: "New data breach",
  name_der_Datenpanne: "Name of the data breach",
  vorabeinschaetzung: "Preliminary assessment",
  assessmentRechtlicherHinweis: "The complexity of a data breach is minimised by the inputs. The recommendation is based on simplified assumptions and may therefore not always be accurate. The recommendation can be one of several indicators for a decision. A data breach should always be considered and assessed in detail in each individual case.",
  meldungAufsichtsbehoerde: "Notification to the supervisory authority",
  meldendePerson: "Reporting person",
  keine_meldungen_vorhanden: "No reportings available",
  meldung_vom: "Reporting from",
  anzahl_zu_klein: "Number too low",
  meldepflichtig: "Reportable",
  faelligkeit: "Maturity",
  eingangsuhrzeit: "Input time",
  datumDerMeldung: "Date of reporting",
  uhrzeitDermeldung: "Time of reporting",
  keine_benachrichtigungen_vorhanden: "No notifications available",
  meldefrist: "Reporting deadline",
  benachrichtigung_vom: "Notification from",
  ansprechperson: "Contact person",
  informationenZumVorfall: "Information about the incident",
  beteiligungDritten: "Participation of third parties",
  risikoeinschaetzung: "Risk assessment",
  dritteBeteiligte: "Third parties involved",
  keineDrittenBeteiligten: "No third parties involved",
  erstelleDritteBeteiligte: "Create a third party",
  moeglicheSchaeden: "Possible damage",
  keineMoeglichenSchaeden: "No possible damage",
  erstelleMoeglicheSchaeden: "Create a possible damage",
  zuordnungRisikoabstufungen: "Allocation to risk classifications",
  meldungen: "Reportings",
  meldung: "Report",
  keineMeldungengemeldet: "No data breaches have yet been reported to a supervisory authority",
  erstelleMeldung: "Create a report",
  grenzueberschreitendeUndAndereBenachrichtigungen: "Cross-border notifications and other notifications",
  informationAndererBehoerden: "Information from other authorities",
  weitereMitteilungenAnDatenschutzaufsichtsbehoerde: "Further notifications to the data protection supervisory authority",
  anrede: "Title",
  funktionImBetroffenenUnternehmen: "Function in the company concerned",
  artDesVorfalls: "Type of the incident",
  beschreibung_des_Vorfalls: "Description of the incident",
  ursacheDesVorfalls: "Cause of the incident",
  vorfallAnEinemBestimmtenZeitpunkt: "Did the incident occur at a specific time or over a period of time?",
  meldendePersonAuchAnsprechpartner: "Is the reporting person also the contact person?",
  dauertDerVorfallNochAn: "Is the incident still ongoing?",
  andauernderVorfall: "Ongoing incident",
  feststellungDesVorfalls: "Determination of the incident",
  ortDesVorfalls: "Location of the incident",
  beschreibung_der_Kenntnisse_des_Vorfalls: "Description of the knowledge of the incident",
  datumDerFeststellungDesVorfalls: "Date of the discovery of the incident",
  uhrzeitDerFeststellungDesVorfalls: "Time of detection of the incident",
  betroffeneKategorienPersonenbezogenerDaten: "Categories of personal data concerned",
  betroffeneBesondereKategorienPersonenbezogenerDaten: "Special categories of personal data concerned",
  kategorienBetroffenerPersonen: "Categories of affected persons",
  anzalDerBetroffenenPersonen: "Number of persons affected",
  anzalDerBetroffenenDatensaetze: "Number of data records affected",
  neuen_vertrag_hochladen: "Upload new contract",
  vertrag_hochladen: "Upload contract",
  du_kannst: "Click",
  hier: "here",
  dsfa_erstellen_pa: "to prepare a data protection impact assessment for this processing activity.",
  ergaenzendeMitteilungenAusichtsbehoerde: "Supplementary notifications to the supervisory authority",
  upfront_confirmed: "Has the contract already been signed by all (future) deposited companies?",
  upfront_confirmed_info: "If this option is activated, the intercompany agreement is automatically confirmed for all companies that have already been added and will be added in the future. You can deactivate this option at any time so that all companies are prompted for confirmation.",
  sindDritteBeteiligt: "Are third parties involved?",
  wurdeDatenpanneGemeldet: "Has the data breach been or will it be reported to a supervisory authority?",
  werdenBetroffeneUeberDatenpanneBenachrichtigt: "Have the data subjects been or will they be notified of the data breach?",
  begründungFuerEntscheidung: "Reasons for the decision",
  wurdenMaßnahmenZurAbmilderungGetroffen: "Have measures already been taken or will measures be taken to mitigate the risk?",
  getroffeneMaßnahmen: "Measures implemented",
  beschreibungGetroffeneMaßnahmen: "Description of measures implemented",
  geplanteMaßnahmen: "Planned measures",
  beschreibungGeplanteMaßnahmen: "Description of measures planned",
  restrisiko: "Residual risk",
  begruendungRestrisiko: "Reasoning of the residual risk",
  funktion: "Function",
  grund: "Reason",
  schwereDesMoeglichenSchadens: "Severity of the potential damage",
  neu: "New",
  artDerMeldung: "Type of report",
  datumDerVorlaufigenMeldung: "Date of the provisional report",
  ReferenznummerAktenzeichenVorläufigenMeldung: "Reference number/file reference of the provisional notification",
  ReferenznummerAktenzeichen: "Reference number/file reference",
  MeldungErfolgtSpaeter: "Is the notification made later than 72 hours after becoming known?",
  begruendungVerspaeteteMeldung: "Reason for the delayed report",
  datumDerBenachrichtigung: "Date of notification",
  uhrzeitDerBenachrichtigung: "Time of the notification",
  artDerBenachrichtigung: "Type of the notification",
  anzahlDerBenachrichtigtenBetroffenen: "Number of the affected data subjects notified",
  inhaltDerBenachrichtigung: "Content of the notification",
  erstellungDerBenachrichtigung: "Creation of the notification",
  moeglicheSchaedenSlider0: "minor",
  moeglicheSchaedenSlider1: "manageable",
  moeglicheSchaedenSlider2: "substantial",
  moeglicheSchaedenSlider3: "high",
  meldendePersonAuchAnsprechpartnerResult: "The reporting person is also the contact person",
  erstmeldung: "First report",
  meldungNichtZuSpaet: "Report will be made within 72 hours of becoming known",
  meldungZuSpaet: "Report takes place later than 72 hours after becoming known",
  ergaenzendeMeldung: "Supplementary report",
  exporttypWaehlen: "Select an export type",
  risiko: "Risk",
  dokumente: "Documents",
  hochgeladeneDokumente: "Uploaded documents",
  welcomeToKeyed: "Welcome to Keyed",
  welcome_text: "Get to know the structure of the DSMS from Keyed® and continuously gain new insights into functions that have already been released. If a function is not yet available, it will be released shortly. Your personal contact at Keyed® will be happy to answer any questions you may have.",
  alle_anzeigen: "Show all",
  alle_Aufgaben_anzeigen: "Show all tasks",
  konformitaet: "Conformity in %",
  neue_pruefung: "Create new audit",
  frist_wiederkehrende_Pruefungen: "Deadline for recurring audits",
  erinnerungsfrist_fuer_Pruefungen: "Reminder period for audits",
  empfaenger_der_Pruefungen: "Receiver of audits",
  backlog: "Backlog",
  logoHochladen: "Upload logo",
  in_bearbeitung: "In progress",
  erledigt: "Done",
  verantwortlicher: "Controller",
  name_der_Verarbeitungstätigkeit: "Name of the processing activity",
  name_der_checkliste: "Name of the checklist",
  name_der_checklistvorlage: "Name of the checklist template",
  name_des_audits: "Name of the audit",
  name_der_auditvorlage: "Name of the audit template",
  zustaendige_abteilungen: "Responsible departments",
  zustaendige_Person: "Responsible person",
  beschreibung_der_Verarbeitungstätigkeit: "Description of the processing activity",
  zwecke_der_Verarbeitungstätigkeit: "Purposes of the processing activity",
  hinzufuegen: "add",
  beschreibung: "Description",
  schadensausmass: "Extent of damage",
  rechtsgrundlage_der_Verarbeitungstätigkeit: "Legal basis of the processing activity",
  datenkategorie: "Data category",
  datenkategorien: "Data categories",
  kategorie: "Category",
  kategorien: "Categorie(s)",
  software: "Software",
  loeschfrist: "Deletion period",
  loeschfristen: "Deletion periods",
  externe_Empfaenger: "External recipients",
  jahresumsatz: "Annual tunrover in euros",
  datenschutzniveau: "Level of data protection",
  vererben: "Inheritance",
  vererbung_erstellen: "Create inheritance",
  letzte_Aenderungen: "Latest changes",
  vererbt_von: "Inherited from {company}",
  vererbungen: "Inheritances",
  vererbung_an: "{name} is currently inherited to the following companies:",
  routine_erstellen: "Create deletion routine",
  keine_vererbung: "{name} has not yet been inherited to any other company.",
  periode_validierung: "For a recurring task, at least one of the entries must be greater than 0.",
  beschreibung_routine: "At this point, you can create a deletion routine. Note that a recurring task is created for each individual deletion period. For that purpose, select the persons who are responsible for the deletion routine for the data category {category} in the processing activity {activity}.",
  richtlinien: "Guidelines",
  richtlinien_text: "Internal guidelines for the handling of personal data provide good orientation for employees. Templates are provided for the development of guidelines.",
  einwilligungen: "Consents",
  einwilligungen_text: "Consent must be given explicitly for specific purposes. You can find some templates for different purposes here.",
  Vertraege_datenempfaenger: "Contracts for data recipients",
  Vertraege_datenempfaenger_text: "An appropriate level of protection must be contractually guaranteed, especially when transferring data. Templates for these contracts are offered here for editing in the usual market constellations.",
  interne_Dokumente: "Internal documents",
  persoenliches_Dashboard: "Personal dashboard",
  zum_persoenlichen_dashboard: "Switch to your Personal dashboard",
  organisationsuebergreifende_Daten: "Here you can see cross-organisational data",
  verarbeitungen: "Processing",
  vertraege: "Contracts",
  offene_Aufgaben: "Open tasks",
  tom_abkuerzung: "TOM",
  allgemeines: "General Information",
  pruefungen: "Audits",
  aendern: "Edit",
  orte_der_Verarbeitung: "Places of processing",
  keine_orte: "No Places of processing",
  geeignete_garantien: "Appropriate guarantees and exceptions",
  unterauftragnehmer: "Subcontractor",
  vertragsunterlagen: "Contract documents",
  vertrag_erstellen: "Create contract",
  verknuepfte_Dokumentation: "Linked documentation",
  einstellungen_fuer_Pruefungen: "Settings for audits",
  ort_der_Verarbeitung: "Place of processing",
  type_communication: "Types of external communication",
  orte_der_verarbeitung: "Places of processing",
  keine_speicherorte: "No Places of processing stored.",
  demo_aufgaben_nicht_verschoben: "The tasks cannot be changed or moved in the demo.",
  fuegen_Sie_eine_Tom_hinzu: "Add a measure",
  umgesetzte_maßnahmen: "Implemented measures",
  geplante_maßnahmen: "Planned measures",
  zwecke_der_Verarbeitung: "Purposes of the processing",
  vorlage: "Template",
  bearbeiten_klein: "edit",
  label: "Label",
  aufgaben_label: "Task Label",
  datenpanne_info_ansehen: "View data breach reports",
  datenpanne_info_erstellen: "Create data breach reports",
  datenpanne_info_bearbeiten: "Edit data breach reports",
  datenpanne_info_loeschen: "Delete data breach reports",
  datenpanne_report_ansehen: "View data breach notification",
  datenpanne_report_erstellen: "Create data breach notification",
  datenpanne_report_bearbeiten: "Edit data breach notification",
  datenpanne_report_loeschen: "Delete data breach notification",
  word_ansehen: "View Word template",
  daten: "Data",
  objekte: "Objects",
  datei: "Files",
  checkliste: "Checklist",
  datenpanne: "Data breach",
  standard_template: "Standard Template",
  standard_resource: "Standard Resource",
  verarbeitungstaetigkeit_auftrag: "Processing on behalf of a controller",
  datenschutzfolgenabschaetzung: "Data protection impact assessment",
  resource_ort: "Resource Location",
  resource_aufbewahrung: "Resource Storage location",
  resource_zweck: "Resource Purpose",
  resource_rechtsgrundlage: "Resource Legal basis",
  resource_personal_data: "Resource Category of personal data",
  resource_special_personal_data: "Resource Special category of personal data",
  resource_betroffene: "Resource Category data subjects",
  resource_massnahme: "Resource Measure",
  resource_loeschfrist: "Resource Deletion period",
  resource_status: "Ressource Status",
  resource_risikoquelle: "Resource Source of risk",
  resource_dokumentenkategorie: "Resource Document category",
  resource_receiver_category: "Resource Category recipient",
  resource_label: "Resource Label",
  resource_aufsicht: "Supervisory Authority",
  resource_datenpanne_typ: "Resource Type data breach",
  resource_datenpanne_grund: "Resource Reason for data breach",
  resource_externe_kommu_typ: "Resource Rype external communication",
  aufgabe: "Task",
  aufgabe_zeiterfassung: "Task Time recording",
  vorlage_verarbeitung: "Template Processing activity",
  vorlage_audit: "Template Audit",
  vorlage_checklist: "Template Checklist",
  vorlage_dsfa: "Template Data protection impact assessment",
  vorlage_dokument: "Template Document",
  person: "Person",
  version: "Version",
  kommentar: "Comment",
  wiederherstellen: "Restore",
  word_erstellen: "Create Word template",
  word_loeschen: "Delete Word template",
  pdf_ansehen: "View PDF template",
  pdf_erstellen: "Create PDF template",
  pdf_bearbeiten: "Edit PDF template",
  pdf_loeschen: "Delete PDF template",
  data_receiver_subcontractor_ansehen: "View data recipient subcontractor",
  data_receiver_subcontractor_erstellen: "Create data recipient subcontractor",
  data_receiver_subcontractor_bearbeiten: "Edit data recipient subcontractor",
  data_receiver_subcontractor_loeschen: "Delete data recipient subcontractor",
  data_receiver_storage_ansehen: "View data recipient processing locations",
  data_receiver_storage_erstellen: "Create data recipient processing locations",
  data_receiver_storage_bearbeiten: "Edit data recipient processing locations",
  data_receiver_storage_loeschen: "Delete data recipient processing locations",
  zwecke: "Purposes",
  exportieren: "Export",
  rechtsgrundlagen: "Legal basis",
  kategorien_personenbezogener_Daten: "Category(ies) of personal data",
  personenbezogene_Daten: "Personal data",
  besondere_kategorien_personenbezogener_Daten: "Special category(ies) of personal data",
  besondere_kategorien_personenbezogener_Daten_vorhanden: "Are special category(ies) of personal data available?",
  besondere_personenbezogener_Daten: "Special personal data",
  kategorien_der_betroffenen: "Category(ies) of data subjects",
  kategorien_der_empfaenger: "Category(ies) of recipients",
  kategorien_dermaßnahmen: "Category(ies) of measures",
  loeschfristen_und_loeschkonzept: "Deletion periods and deletion concept",
  datenuebertragung_in_drittlaender: "Data transfer to a third country",
  risikobewertung: "Risk assessment",
  typen: "Types",
  massnahmen_nicht_umgesetzt: "The following selected measures have not yet been implemented:",
  risikoszenarien_bearbeiten: "Edit risk scenarios",
  verarbeitungsvorgaenge_beschreiben: "Describe the processing operations in detail here, taking into account the purposes of the processing activity.",
  betroffene: "Data subjects",
  risikoquellen_waehlen: "Select one or more sources of risk and explain the causes and potential damage in each case.",
  empfaenger: "Recipients",
  maßnahmen: "Measures",
  bewertung: "Assessment",
  tochter_erstellen: "Create subsidiary company",
  weiter: "Next",
  zurueck: "Back",
  zuruecksetzen: "Reset",
  spezifische_massnahmen: "Specific measures",
  neue_aufgabe: "New task",
  dashboard: "Dashboard",
  machen_Sie_weiter: "Continue where you left",
  organisationen: "Organisations",
  meine_aufgaben: "My tasks",
  zu_meinen_aufgaben: "Switch to my tasks",
  zuletzt_verwendet: "Last used",
  aufgaben: "Tasks",
  tabelle_anzeigen: "View table",
  suchen: "Search",
  umgesetzt: "Implemented",
  vollstaendiges_konzept: "Complete concept",
  historie_loeschroutinen: "History of deletion routines",
  loeschroutine_erstellen: "Create deletion routine",
  loeschroutine: "Deletion routine",
  benutzer: "User",
  relevanzpruefung: "Relevance check",
  risiko_wahrscheinlichkeit: "Risk Propability",
  risiko_ausmaß: "Risk Dimension",
  erstellt_von: "Created by",
  risikoanalyse: "Risk assessment",
  ergebnis: "Result",
  erfassung_von_informationen_zur_relevanzpruefung: "Collection of information for the relevance check",
  gesetzlicheFragen: "Legal questions",
  weitereFragen: "Further risk questions",
  risikoszenarioErstellen: "Create risk scenario",
  risikoszenarien: "Risk scenarios",
  risikoquelle: "Source of risk",
  risikoquellen: "Sources of risks",
  rollen: "Roles",
  keine_beschreibung: "No description available.",
  rolle: "Role",
  zustaendig_personen: "Responsible persons",
  zustaendig_gruppen: "Responsible groups",
  schreibe_kommentar: "Write a comment...",
  begruendungUndUrsacheDerRisikoquelle: "Reason and cause of the source of risk",
  potenzielleSchaeden: "Potential damage",
  eintrittswahrscheinlichkeit: "Probability of occurrence",
  schadensausmaß: "Extent of damage",
  Bitte_bestätige_zutreffende_Fragen_durch_die_Betätigung_der_Schaltfläche_und_füge_ggf_eine_Beschreibung_hinzu: "Please confirm applicable questions by clicking the button and add a description if necessary.",
  Bitte_bestätige_zutreffende_einschlägige_Fragen_durch_die_Betätigung_der_Schaltfläche_und_füge_ggf_eine_Beschreibung_hinzu: "Please confirm applicable relevant questions by clicking the button and add a description if necessary.",
  AusgewaehlteRisikoquellen: "Selected sources of risk",
  dsfa_erstellen: "Create data protection impact assessment",
  risikoquelleErstellen: "Create source of risk",
  risikoquellenHinzufuegen: "Add source of risks",
  weiteresRisikosezenarioErfassen: "Add risk scenario",
  WeiterZurRisikoanalyse: "Risk assessment",
  RelevanzpruefungFortsetzen: "Continue relevance check",
  EsIstEineDatenschutzfolgenabschaetzungErforderlich: "A data protection impact assessment is required.",
  ErgebnisVorMaßnahmen: "Result without measures taken",
  ZusammenfassungDerRisiken: "Summary of risks",
  ErgebnisNachMaßnahmen: "Result after measures taken",
  Ergebnis: "Result",
  risikoszenario: "Risk scenario",
  bruttorisikowert: "Gross risk value",
  brutto_risk_probability: "Gross risk value of the probability of occurrence",
  brutto_risk_severity: "Gross risk value of the extent of damage",
  netto_risk_probability: "Net risk value of the probability of occurrence",
  netto_risk_severity: "Net risk value of the extent of damage",
  netto_risk_scenario: "Net risk value of the scenario",
  nettorisikowert: "Net risk value",
  nettowertBerechnen: "Calculate net risk value",
  nameDesRisikoszenarios: "Name of the risk scenario",
  verarbeitungsorte: "Processing locations",
  auswahlToms: "Select one or more measures",
  hinweisVonDerDigitalenRechtsassistenz: "Note from the digital legal assistant",
  spezifische_angaben_zur_Verarbeitung: "Specific information about processing",
  OeffneDiesesElementUmMöglicheHinweiseEinzusehen: "Open this element to view possible hints",
  RisikobewertungOhneMaßnahmen: "Risk assessment without measures taken",
  bewertenSieDieAusgewaehltenToms: "Evaluate the selected TOM(s)",
  EineRisikoanalyseIstNichtErforderlich: "A risk analysis is not necessary",
  DSFABeendenUndSpeichern: "Exit and save DPIA",
  bitte_auswahl: "Please make a selection first",
  themenkapitel_frage_auswaehlen: "Choose chapter or question",
  ja_nein_frage: "Yes/No - Question",
  freitext: "Freetext - Question",
  einfachauswahl: "Single Select Question",
  mehrfachauswahl: "Multiselect Question",
  aufgabeBearbeiten: "Edit task",
  frageBearbeiten: "Edit question",
  themenkapitelBearbeiten: "Edit topic chapter",
  zutreffend: "Applicable",
  fuegen_sie_eine_frage_hinzu: "Add a question",
  hinzufuegen_themenkapitel: "Add a topic chapter",
  hinzufuegen_themenkapitel_oder_frage: "Add a topic chapter or a question",
  autor: "Author",
  frage: "Question",
  ica_officer: "Responsible user for Intercompany Agreements",
  no_logo: "No company logo present",
  zustaendig: "Responsible",
  wiederkehrend: "Recurring",
  benutzerverwaltung: "User administration",
  darstellung: "View",
  darstellung_sonstiges: "Presentation & Other",
  auswertungen_und_berichte: "Analyses & reports",
  allgemeine_massnahmen: "General measures",
  massnahme: "Measure",
  massnahmen: "Measures",
  verlinkung: "Linking",
  verarbeitungstaetigkeiten: "Processing activities",
  verarbeitungstaetigkeit: "Processing activitiy",
  unternehmen: "Company",
  datenschutzverletzungen: "Data breach",
  filtern: "Filter",
  risikowert: "Risk value",
  abteilung_unabhängig: "Department-independent view of objects",
  betroffenenanfragen: "Request of data subjects",
  AnzahlDerVerarbeitungstaetigkeiten: "Number of processing activities",
  AnzahlVerarbeitungstaetigkeiten: "Number of processing activities",
  vertragsstatus: "Contract status",
  AnzahlDerDatenschutzfolgenabschaetzungen: "Number of data protection impact assessments",
  AnzahlDerTechUndOrgMaßnahmen: "Number of technical and organisational measures",
  AnzahlDerAudits: "Number of audits",
  sonstiges: "Other",
  farbeWaehlen: "Select colour",
  kopfzeilen: "Header",
  fußzeilen: "Footer",
  spaltenAnzahl: "Number of columns",
  spalteHinzufuegen: "Add column",
  spalteEntfernen: "Remove column",
  spaltenHoehe: "Column height",
  textAbstandWaehlen: "Select text spacing",
  oben: "Top",
  unten: "Down",
  zurueck_dashboard: "Return to dashboard",
  AnzahlDerDatenschutzverletzungen: "Number of data breaches",
  AnzahlDerBetroffenenanfragen: "Number of requests from data subjects",
  AnzahlDerAufgaben: "Number of tasks",
  NeueDSFAErstellen: "Would you like to create the new DPIA for an existing processing activity?",
  erstellungEinerTom: "Creation of a technical and organisational measure",
  erstellungEinerDSFA: "Creation of a DPIA",
  erstellungEinerDatenpanne: "Creation of a data breach",
  erstellungEinerExternenKommunikation: "Creation of an external communication",
  erstellungEinerCheckliste: "Creation of a checklist",
  erstellungEinerChecklisteAuswahl: "Which existing template would you like to use to create a checklist?",
  erstellungEinesAudit: "Creation of an audit",
  erstellungEinesAuditAuswahl: "Which existing template would you like to use to create an audit?",
  NeueVVTErstellen: "Would you like to create the new processing activity based on an existing template?",
  erstellungEinerVearbeitungstaetigkeit: "Creation of a processing activity",
  NeueExterneKommunikationErstellen: "Would you like to create a task that relates to the new external communication to be created?",
  NeueDatenpanneErstellen: "Would you like to create a task that relates to the new data breakdown to be created?",
  keine_risikoquellen: "No risk sources have been created yet.",
  keine_massnahmen: "No measures have been selected yet.",
  keine_massnahmen_dsfa: "No measures available.",
  esWurdenNochKeineMaßnahmenZurAbmilderungDesRisikosGetroffen: "No measures have yet been taken to mitigate the risk",
  kleiner2: "< 2",
  groeßer2: "> 2",
  null_wort: "0",
  kleiner50: "< 50",
  kleiner70: "< 70",
  einhundertZahl: "100",
  geprueft: "Checked",
  offen: "Open",
  geplant: "Planned",
  scoringwert: "Scoring value",
  nein: "No",
  max_upload_multiple: "Note: All selected files must not exceed 20 MB in size.",
  max_upload: "Note: All selected files must not exceed 20 MB in size.",
  bearbeitungshinweis: "Editing note",
  beteiligteEmpfaenger: "Involved recipients",
  WählenSieeinJahraus: "Select a year",
  WählenSieeinQuartalaus: "Select a quarter",
  WählenSieEinAbgeschlossenesAuditAus: "Select a completed audit",
  allgemeineInformationen: "General information on the processing activity",
  dsfa_keine_informationen: "There are no informations on the processing activity yet, please complete them.",
  informationen_erstellen: "Create informations",
  InformationenBearbeiten: "Edit informations",
  pdf_vorlagen: "PDF templates",
  BearbeitungDerBenötigtenInformationen: "Editing of the required information",
  response_400_measure: "This measure has already been assigned to this company.",
  response_400_inheritance: "This property has already been inherited to this company.",
  response_400_subcontractor: "The subcontractor is already stored for this data recipient.",
  response_400_storage_location: "This processing location already exists for this data recipient.",
  http_413: "Upload failed. The file size of 20 MB has been exceeded.",
  MI: "Minutes",
  HO: "Hours",
  anschrift: "Adress",
  zustaendige_personen: "Responsible person",
  DA: "Days",
  WE: "Weeks",
  MO: "Months",
  YE: "Years",
  DA_single: "Day",
  WE_single: "Week",
  MO_single: "Month",
  YE_single: "Year",
  beschreibung_kategorien_verarbeitungen: "Description of the categories of processing operations",
  pa_1: "The name of the processing activity also serves as the title of the processing for all overviews (e.g. register of processing activities, deletion concept) within the DSMS. It is recommended that the name of the processing activity should be independent of individual IT tools that may be used in the context of processing.",
  pa_2: "Select the responsible persons here who have process responsibility for this processing. Note that the first person is also automatically entered in the deletion concept for the processing.",
  pa_3: "Select the relevant departments from the company here.",
  pa_4: "Describe the processing operations in detail, taking into account the purposes of the processing activity.",
  pa_5: "Select the relevant purposes for the processing activity pursuant to Art. 30 (1) lit. b) GDPR.",
  pa_6: "Every processing activity must be legitimised by a legal basis. Select the relevant legal basis here in accordance with Art. 6 GDPR.",
  pa_7: "Select the appropriate categories of personal data for the processing activity in accordance with Art. 30 (1) lit. c) GDPR. Note that the selection of categories has an impact on the risk assessment of the processing.",
  pa_8: "Select the applicable special categories of personal data of the processing activity in accordance with Art. 30 (1) lit. c) GDPR. Note that the selection of categories has an impact on the risk assessment of the processing.",
  pa_9: "Select the appropriate categories of data subjects for the processing activity in accordance with Art. 30 (1) lit. c) GDPR. Note that the selection of categories has an impact on the risk assessment of the processing.",
  pa_10: "Select the internal recipients (departments) involved in the processing activity.",
  pa_11: "Select the external recipients (data recipients, usually processors) involved in the processing activity. Note that the selection of external recipients has an influence on the risk assessment of the processing.",
  pa_11_kategorien_empfaenger: "Select the categories of recipients involved in the processing activity.",
  pa_12: "Assign the respective deletion periods to each category of personal data in accordance with Art. 30 (1) lit. f) GDPR. Note that this assignment is used by the DSMS as the basis for the erasure concept. The deletion of the data categories in accordance with the deletion periods must only take place after the purpose has ceased to apply.",
  pa_13: "Take specific technical and organisational measures pursuant to Art. 30 (1) lit. g) in conjunction with Art. 32 (1) GDPR to minimise the risk of the processing activity.",
  pa_14: "Evaluate the general technical and organisational measures pursuant to Art. 30 (1) lit. g) in conjunction with Art. 32 (1) GDPR to minimise the risk of the processing activity.",
  pa_15: "The assessment of the processing activity is based on the evaluation of all the information in the previous steps. The evaluation of the risk can already be used as a guide to assess whether a data protection impact assessment (DPIA) should be carried out. Note that the processing activity can already be transferred to a DPIA to ensure efficient further processing.",
  pa_16: "Use the risk to assess how sensitive the date is. The more sensitive the date, the higher the risk.",
  pa_17: "Select here whether data is transferred to a third country. If external recipients have been selected who have their registered office or storage locations in a third country, the third country transfer is automatically analysed.",
  pa_18: "At this point, describe the categories of processing that are carried out on behalf of the company.",
  Info_P2: "This processing is intended to process data that is subject to special confidentiality (e.g. professional secrecy). Other organisations therefore assess the extent of damage as high and take measures to anonymise, reduce and encrypt the data records.",
  Info_P5: "This processing consists of systematic, extensive monitoring of publicly accessible areas. Other organisations therefore rate the probability of occurrence as high and take measures against the unauthorised disclosure of this data by implementing authorisation concepts and deletion concepts.",
  Info_O10: "In this processing, data of particularly sensitive persons (e.g. children, employees) are to be processed. Other organisations therefore assess the extent of damage as high and take measures to anonymise or pseudonymise data.",
  Info_P1_11_13_14_O5: "This processing is intended to process special categories of personal data. Other organisations therefore assess the extent of damage as high and take measures to anonymise and reduce the data sets.",
  Info_P9_10_14_15_O9: "This processing uses innovative technologies. Other organisations rate the probability of occurrence as high and take implementation measures for test systems and test cycles, as well as automatic monitoring of the technologies to contain the probability of occurrence.",
  Info_P6_7_8_12_O11: "This processing consists of the collection of data that can be used to assess the behaviour of natural persons. Other organisations consider the level of damage to be high and take measures to pseudonymise and encrypt the data records.",
  Info_O12_14: "Data recipients are used who may be required by a third-country authority to export or disclose this data contrary to Art. 48 GDPR. Other organisations assess the probability of occurrence as high and take measures to encrypt the data to protect it from access by data recipients or authorities from third countries.",
  kein_drittland: "There is no data transfer to a third country.",
  unsicher_drittland: "There is a data transfer to a third country.",
  sicher_drittland: "Data is transferred to a secure third country (adequacy decision).",
  sicheres_drittland: "Safe Third Country",
  measure_risk_0: "Ineffective",
  measure_risk_1: "Low effectiveness",
  measure_risk_2: "Normal effectiveness",
  measure_risk_3: "High effectiveness",
  wahr: "True",
  falsch: "False",
  measure_risk_4: "Very high effectiveness",
  maßnahmeVertraulichkeit: "Measures to ensure confidentiality",
  erfolgreich_speichern: "Successfully saved",
  fehler_speichern: "Error while saving",
  nicht_erforderlich: "Not required",
  erfolgreich_umgesetzt: "Successfully implemented",
  nicht_umgesetzt: "Not implemented",
  aufbewahrungsorte: "Storage locations",
  ausgewaehlt_elemente: "Selected elements",
  mehr_anzeigen: "Show more",
  erstellt_am: "Created at",
  filter_zuruecksetzen: "Reset all filters",
  typ: "Typ",
  error_deleted_user: "A deleted user may not be selected.",
  weitere_fragen: "Further risk questions",
  informationen_erzeugt_durch_Verarbeitungstätigkeit: "This area shows information generated by the processing of the record of processing activities.",
  informationen_erzeugt_durch_Verarbeitungstätigkeit_erasure: "This area displays information generated by the processing of the record of processing activities. The deletion of the data categories in accordance with the deletion periods must only take place after the purpose has ceased to apply.",
  inhalte: "Contents",
  anzahl: "Number",
  stundenanzahl: "Number of hours",
  einheit: "Unit",
  keine_benachrichtigungen: "No notifications",
  keine_quellen: "No sources of risk selected.",
  kein_zugriff: "You have no access to this company.",
  personen: "Persons",
  massnahmen_erstellen: "Create all measures for the company",
  alle_massnahmen_erstellen: "Do you really want to create all measures for the company",
  verarbeitung_erstellen: "Create processing activity",
  verarbeitungs_wirklich_erstellen: "Do you really want to create a processing activity from this information?",
  verarbeitung_dsfa: "Open data protection impact assessment",
  zur_verarbeitung: "Open processing activity",
  kategorien_vererbung: "The categories {categories} have been inherited and cannot be removed or edited.",
  aufgaben_benachrichtigung: "You have been assigned to the task {name}",
  export_benachrichtigung: "Download {name}",
  dsfa_risiko_ergebnis: "Before measures taken, the result is {brutto}. The taken measures reduce the risk to {netto}.",
  vorname: "First name",
  nachname: "Last name",
  pdf: "PDF",
  word: "Word",
  word_vorlagen: "Word templates",
  word_vorlage: "Word template",
  herunterladen: "Download",
  export_vorlagen: "Export templates",
  tom_hinweis: "Only selected technical and organisational measures for this processing activity are mentioned here. General technical and organisational measures can be found in the <a class='measures'>TOM-Overview</a>.",
  tom_hinweis_im_auftrag: "You can find an overview of the general technical and organisational measures in the <a class='measures'>TOM-Overview</a>.",
  erasure_concept_hint: "You can find more information on our erasure periods and storage locations in our <a class='erasure'>erasure concept</a>.",
  interne_empfaenger: "Internal receivers",
  externe_empfaenger: "External receiver",
  keine_externen_datenempfeanger: "No external data recipients",
  keine_kategorien_der_empfaenger: "No category(ies) of recipients",
  hinweis: "Hint",
  receiver_location_hint: "The registered office of the recipient is located in {country}. This country is not part of the European Economic Area and there is no adequacy decision.",
  receiver_location_secure_hint: "The recipient's registered office is in {country}. It is a third country with an adequacy decision.",
  erlaeuterung: "Explanation",
  farben: "Colours",
  receiver_type_DTL: "Service provider",
  receiver_type_GMV: "Jointly responsible",
  receiver_type_ATV: "Processor",
  receiver_type_SON: "Other",
  receiver_type_ICA: "Intercompany Agreement",
  primaer: "Primary",
  no_documents: "No linked documents",
  no_audits: "No linked audits",
  no_labels: "No Labels chosen",
  appleiste_hintergrund: "Background app bar",
  hintergrund: "Background",
  fehler: "Error",
  warnung: "Warning",
  erfolg: "Success",
  info: "Information",
  informationen: "Informations",
  berechtigungen: "Authorisations",
  ansehen: "View",
  bearbeiten: "Edit",
  gewichtung: "Weighting",
  gewichtungWaehlen: "Select a weighting between 0 and 10",
  erstellen_gross: "Create",
  create_checklist_question_info: "Notes on the creation of questions",
  create_checklist_question_hint: "Create the question so that it can be answered with yes or no.",
  create_checklist_question_hint_freetext: "Create the question in such a way that the answer can be formulated individually.",
  create_checklist_question_hint_singleselect: "Create the question in such a way that only one answer is relevant.",
  create_checklist_question_hint_multiselect: "Create the question in such a way that several answers can apply.",
  question_hint_general: "First enter the question type to obtain further information on how to answer the question.",
  weitere_info_risiko: "Further information on the risk calculation",
  risk_chart_hint: "The risk is presented as a gross risk (before measures taken) and as a net risk (after measures taken). The gross risk consists of the extent of damage and the probability of occurrence of a personal data breach. The extent of the damage is calculated on the basis of the categories of personal data, the categories of data subjects and the data transfer to a third country. The information on data transfer to a third country and the deletion periods are used to calculate the probability of occurrence. All information available for selection contains risk values and weightings for influencing the risk. Finally, the gross risk is minimised by the selected technical and organisational measures (TOM) and presented as a net risk.",
  riskchart: {
    bruttorisiko: "Gross risk (excluding measures)",
    nettorisiko: "Net risk (minus measures)",
  },
  rules: {
    empty_field: "This field must not be empty.",
    integer_rule: "Only integers without separators may be used",
  },
  einblenden: "show",
  vererbung: "Inheritance",
  jahresbericht: "Annual report",
  jahresbericht_erklaerung: "The annual report is a good tool for summarising the activities and changes of the past period in data protection. Important key figures provide an indication for the management.",
  ja: "Yes",
  themenkapitel: "Topic chapter",
  conformity_result: "The conformity of the entire audit amounts to",
  jahresberichtErstellen: "Create annual report",
  auditberichtErstellen: "Create audit report",
  auditbericht: "Auditreport",
  auditbericht_erklaerung: "The audit report summarises the results of a selected audit and serves as the basis for discussing an audit.",
  bericht_erstellen: "Create report",
  impact_assessment_recommendation: "We recommend that you carry out a data protection impact assessment for this processing activity.",
  critical_country: "The country {country} is not part of the European Economic Area and there is no adequacy decision.",
  critical_countries: "The countries {countries} are not part of the European Economic Area and there is no adequacy decision.",
  secure_country: "An adequacy decision exists for the country {country}.",
  data_receiver_warning_country: "This country is not part of the European Economic Area.",
  data_receiver_secure_country: "This country is not part of the European Economic Area, but there is currently an adequacy decision.",
  secure_countries: "An adequacy decision exists for the countries {countries}.",
  nicht_speichern_wegen_standard: "This resource is a standard resource. It can therefore not be edited or changed.",
  total_risk: {
    netto_simple: "(Net) overall risk",
    simple: "Overall risk",
    impact_assessment: "The data protection impact assessment is likely to have {risk}.",
    processing_ctivity: "The processing activity is likely to have {risk}.",
    total_risk_0: "no risk",
    total_risk_1: "low overall risk",
    total_risk_2: "medium overall risk",
    total_risk_3: "high overall risk",
    total_risk_4: "very high overall risk",
  },
  bestaetigt: "Confirmed",
  risk_0: "No risk",
  risk_1: "Low",
  risk_2: "Medium",
  risk_3: "High",
  risk_4: "Very high",
  datenschutzerklaerungen: "Privacy policy",
  datenschutzerklaerungen_text: "In order to fulfil the information obligations from Art. 12 ff. GDPR, step-by-step data protection declarations can be created here for various purposes.",
  vorlage_auswaehlen: "Select template",
  info_ica: "If you are acting as a processor acc. to Art. 4 (8) GDPR within the framework of an intercompany agreement, you can assign the applicable contractual relationships by making this selection in the following step.",
  vorlage_auswaehlen_tabelle: "Select template",
  ica_upfront_confirmed_hint: "An already signed Intercompany Agreement has been uploaded, therefore the management of confirmations is not available.",
  tochtunternehmen_inkludieren: "Should all subsidiaries of the selected companies also be included?",
  achtung_loeschen: "ATTENTION - You remove the user from all organizations.",
  csv_hochladen: "Upload csv",
  rolle_aktualisieren: "Update role",
  benutzereinstellungen: "User settings",
  hinweis_benutzer_loeschen_plural: "The users selected for deletion remain in place for 30 days, but can no longer be selected or access any of the companies within the organizational structure. After the 30 days have expired, the users are permanently removed.",
  hinweis_vererbte_datenempfänger_nicht_angezeigt: "Inherited data receivers are not displayed in this selection dialog if the processing activity has already been inherited by another company. This is because inherited data receiver may not be inherited.",
  $vuetify: {
    ...en,
  },
};
