<template>
    <v-dialog v-model="dialog" hide-overlay max-width="450" content-class="save-hint-dialog">
        <!-- persistent -->
        <v-alert :color="color" class="ma-0">
            <v-row align="center">
                <v-col cols="auto">
                    <v-icon color="white">mdi-check-circle</v-icon>
                </v-col>
                <v-col class="white--text">
                    {{ message }}
                </v-col>
                <v-col cols="auto">
                    <v-btn icon @click="close()">
                        <v-icon color="white">mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>
    </v-dialog>
</template>

<script>
export default {
    data: () => ({
        color: "success",
        icon: "",
        message: "",
        dialog: false,
    }),
    methods: {
        open(promise, success_msg = null, error_msg=null) {
            promise.then((resp) => {
                if (resp.status != 200 && resp.status != 201 && resp.status != 204) {
                    // Fehler
                    if (error_msg) {
                        this.setError(resp, error_msg)
                    } else {
                        this.setError(resp)
                    }
                } else {
                    // Erfolgreich
                    this.setSuccess(success_msg)
                }
                return resp;
            }).catch((resp) => {
                // Set error
                this.setError(resp, error_msg)
                return resp;
            }).finally((resp) => {
                this.dialog = true;
                return resp;
            })
        },
        close() {
            this.dialog = false;
        },
        setSuccess(success_msg = null) {
            this.color = "success";
            this.icon = "check-circle";
            this.message = success_msg ? success_msg : this.$t("erfolgreich_speichern")
        },
        setError(resp, error_msg=null) {
            let msg_key = resp.response.data


            this.color = "error";
            this.icon = "alert-circle";

            if (Object.hasOwn(msg_key, 'detail')) {
                msg_key = msg_key['detail'];
            }

            if (msg_key != "" && this.$te(msg_key)) {
                this.message = this.$t(msg_key)
            } else if (this.$te('http_' + resp.response.status)) {
                // Error code
                this.message = this.$t('http_' + resp.response.status)
            } else {
                if (error_msg) {
                    this.message = error_msg;
                } else {
                    this.message = this.$t("fehler_speichern");
                }
                
            }
        }
    }
};
</script>

<style>
.save-hint-dialog {
    position: absolute;
    top: 25vh;
}
</style>